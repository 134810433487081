import { HTMLProps } from "react"
import { css, useTheme } from "@emotion/react"
import { useAppDispatch, useAppSelector } from "src/state/redux/hooks"
import { playerActions } from "src/state/redux/player.slice"
import { USER } from "src/utils/constants"
import { useTranslation } from "react-i18next"

const rootCss = css({
  height: "4px",
  borderRadius: "2px",
  appearance: "none",
  margin: "6px 0",
  cursor: "pointer",
  "&:focus": { outline: "none" },
  "&::-webkit-slider-thumb": {
    boxShadow: "none",
    border: "2px solid var(--accent-color)",
    height: "16px",
    width: "16px",
    borderRadius: "99px",
    background: "#fff",
    appearance: "none",
  },
  "&::-moz-range-thumb, &::-ms-thumb": {
    boxShadow: "none",
    border: "2px solid var(--accent-color)",
    height: "16px",
    width: "16px",
    borderRadius: "25px",
    background: "#ffffff",
    cursor: "pointer",
  },
})
export function VolumeSlider(props: HTMLProps<HTMLInputElement>) {
  const playerStatus = useAppSelector((state) => state.player.playerStatus)
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const bgColor = theme.colors.text.ternary
  const { volume } = playerStatus
  const percent = `${volume * 100}%`
  const { t } = useTranslation()
  return (
    <input
      style={{
        ["--accent-color" as any]: theme.colors.accent,
        // To get a different color before and after the handle, we hack by setting a gradient background (the track is hidden and the handle overflows)
        background: `linear-gradient(to right, var(--accent-color) 0%, var(--accent-color) ${percent}, ${bgColor} ${percent}, ${bgColor} 100%)`,
      }}
      aria-label={t("Volume")}
      css={rootCss}
      type="range"
      min={0}
      max={1}
      step={0.01}
      value={volume}
      {...props}
      onChange={(e) => {
        props.onChange?.(e)
        const volume = Number(e.currentTarget.value)
        dispatch(playerActions.setVolume(volume, USER.PHYSICAL))
      }}
    />
  )
}
