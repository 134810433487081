import { useTheme } from "@emotion/react"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getIsPlayingTrack } from "src/state/redux/player.selectors"
import { analyser } from "src/state/services/audioPlayer"

const barsCount = 4
const defaultHeights: number[] = [2, 2, 2, 2]

const barMaxHeight = 12

const sum = (arr: number[] | Uint8Array) => [...arr].reduce((a, b) => a + b, 0)
const mean = (arr: number[] | Uint8Array) => sum(arr) / arr.length

export function PlayingIcon() {
  const { colors } = useTheme()
  const [heights, setHeights] = useState(defaultHeights)
  const isPlaying = useSelector(getIsPlayingTrack)

  useEffect(() => {
    if (!isPlaying || !analyser) {
      setHeights(defaultHeights)
      return
    }
    let raf: number
    function draw() {
      raf = requestAnimationFrame(draw)
      const dataArray = new Uint8Array(analyser.frequencyBinCount)
      analyser.getByteFrequencyData(dataArray)
      // we use only the center of the spectrum because this is where there is to most movement
      const start = Math.floor(dataArray.length * 0.1)
      const end = Math.floor(dataArray.length * 0.25)
      const barRange = (end - start) / barsCount
      setHeights(
        new Array(barsCount)
          .fill(0)
          .map((_, i) =>
            mean(
              dataArray.slice(start + i * barRange, start + (i + 1) * barRange),
            ),
          )
          // multiply by 2 to get higher bars. Max height will be limited by CSS
          .map((height) => 2 + (height / 255) * barMaxHeight * 2),
      )
    }
    draw()
    return () => {
      cancelAnimationFrame(raf)
    }
  }, [isPlaying])

  return (
    <div
      style={{
        display: "flex",
        gap: 4,
        alignItems: "end",
        height: barMaxHeight,
      }}
    >
      {heights.map((height, i) => (
        <div
          key={i}
          style={{
            height,
            width: 3,
            backgroundColor: colors.accent,
            borderRadius: 9,
            maxHeight: barMaxHeight,
          }}
        />
      ))}
    </div>
  )
}
