import styled from "@emotion/styled"
import { Theme, css } from "@emotion/react"
import { Box } from "./Box"

const sizeMap = (theme: Theme) => ({
  md: css({
    height: "2.25rem",
    minWidth: "2.25rem",
    padding: "0 12px",
    ...theme.text.sm,
  }),
  lg: css({
    height: "3rem",
    minWidth: "3rem",
    padding: "0 .5rem",
  }),
  xl: css({
    height: "4rem",
    minWidth: "4rem",
    padding: "0 1rem",
  }),
})

type ButtonSize = keyof ReturnType<typeof sizeMap>

export const Button = styled(Box.withComponent("button"))<{
  size?: ButtonSize
  primary?: boolean
  ball?: boolean
}>(({ size = "md", theme, primary, ball }) => [
  {
    display: "flex",
    alignItems: "center",
    borderRadius: theme.radius.full,
    border: theme.border.subtle,
    background: "none",
    color: theme.colors.text.primary,
    justifyContent: "center",
    fontWeight: theme.font.weight.medium,
    cursor: "pointer",
    transition: "all .2s ease",
    borderWidth: 2,
  },
  sizeMap(theme)[size],
  ball && { padding: "0 .25rem" },
  primary
    ? css({
        borderColor: "transparent",
        background: theme.colors.accent,
        color: theme.colors.white,
      })
    : css({
        ":hover": {
          border: theme.border.full,
          color: theme.colors.text.hover,
        },
      }),
])
