import styled from "@emotion/styled"
import { Box } from "./Box"

const contentPadding = "2rem"

/** Constrains content to maxContentWidth and center it */
export const Container = styled(Box)(({ theme }) => ({
  maxWidth: `calc(${theme.maxContentWidth} + ${contentPadding} * 2)`,
  margin: "0 auto",
  padding: `0 ${contentPadding}}`,
}))
