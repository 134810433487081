import { md5 } from "hash-wasm"
import log from "../log"

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes"

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

export const silenceUrl = new URL("../../public/silence.m4a", import.meta.url)
  .href

export const combinePaths = (...parts: Array<string>): string =>
  parts
    .filter(Boolean)
    .map((part, i) => {
      if (i > 0) {
        part = part.replace(/^\/+/u, "")
      }
      if (i < parts.length - 1) {
        part = part.replace(/\/+$/u, "")
      }
      return part
    })
    .join("/")

const createMD5HashFromBlob = async (blob: Blob): Promise<string> => {
  const arrayBuffer = await blob.arrayBuffer()
  const uint8Array = new Uint8Array(arrayBuffer)
  return md5(uint8Array)
}

export const checkFileHash = async (
  path: string,
  trackBlob: Blob,
  hash: string,
): Promise<boolean> => {
  try {
    const fileHash = await createMD5HashFromBlob(trackBlob)
    return hash === fileHash
  } catch (e) {
    const message = getErrorMessage(e)
    log.error(
      `[Utils][checkFileHash] Hashing failed for file ${path}: ${message}`,
    )
    return false
  }
}

export function formatTime(ms: number) {
  const seconds = Math.floor(Math.abs(ms / 1000))
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.round(seconds % 60)
  const t = [h, m > 9 ? m : h ? "0" + m : m || "0", s > 9 ? s : "0" + s]
    .filter(Boolean)
    .join(":")
  return ms < 0 && seconds ? `-${t}` : t
}

// Function that returns the next date where the time timeToReachInMinutes
// is reached during the current or the next day
export const nextDateWithMinutes = (timeToReachInMinutes: number) => {
  const newDate = new Date()
  const currentMinuteOfTheDay = newDate.getHours() * 60 + newDate.getMinutes()

  if (currentMinuteOfTheDay > timeToReachInMinutes) {
    // That mean that the time will be reach the next day
    newDate.setDate(newDate.getDate() + 1) // automatically carries to next month
  }

  const hours = Math.floor(timeToReachInMinutes / 60)
  const minutes = timeToReachInMinutes % 60
  newDate.setHours(hours, minutes, 0, 0)

  return newDate
}

export const getTimezone = () => {
  // Return the timezone of the browser
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const getErrorMessage = (error: any) => {
  if (typeof error === "string") {
    return error
  }
  if (error.message) {
    return error.message
  }
  return "Unknown error"
}

export const pipe: {
  <R0, R1, R2, R3, R4, R5, R6, R7>(
    value: R0,
    f1: (a: R0) => R1,
    f2: (a: R1) => R2,
    f3: (a: R2) => R3,
    f4: (a: R3) => R4,
    f5: (a: R4) => R5,
    f6: (a: R5) => R6,
    f7: (a: R6) => R7,
  ): R7
  <R0, R1, R2, R3, R4, R5, R6>(
    value: R0,
    f1: (a: R0) => R1,
    f2: (a: R1) => R2,
    f3: (a: R2) => R3,
    f4: (a: R3) => R4,
    f5: (a: R4) => R5,
    f6: (a: R5) => R6,
  ): R6
  <R0, R1, R2, R3, R4, R5>(
    value: R0,
    f1: (a: R0) => R1,
    f2: (a: R1) => R2,
    f3: (a: R2) => R3,
    f4: (a: R3) => R4,
    f5: (a: R4) => R5,
  ): R5
  <R0, R1, R2, R3, R4>(
    value: R0,
    f1: (a: R0) => R1,
    f2: (a: R1) => R2,
    f3: (a: R2) => R3,
    f4: (a: R3) => R4,
  ): R4
  <R0, R1, R2, R3>(
    value: R0,
    f1: (a: R0) => R1,
    f2: (a: R1) => R2,
    f3: (a: R2) => R3,
  ): R3
  <R0, R1, R2>(value: R0, f1: (a: R0) => R1, f2: (a: R1) => R2): R2
} = (initialValue: any, ...functions: ((a: any) => any)[]) => {
  return functions.reduce((result, f) => f(result), initialValue)
}
