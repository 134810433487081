import { ThemeName } from "src/styles/theme"
import { ListeningHistory } from "../redux/player.slice"

const setOrRemoveItem = (key: string, value: string | undefined) => {
  if (value === undefined) localStorage.removeItem(key)
  else localStorage.setItem(key, value)
}

export const stringStorage = <T extends string>(key: string) => ({
  get: () => (localStorage.getItem(key) as T) ?? undefined,
  set: (value: T | undefined) => setOrRemoveItem(key, value),
})

export const jsonStorage = <T>(key: string) => ({
  get: () => {
    const value = localStorage.getItem(key)
    if (value === null) return undefined
    try {
      return JSON.parse(value)
    } catch (e) {
      return undefined
    }
  },
  set: (value: T | undefined) =>
    setOrRemoveItem(
      key,
      value === undefined ? undefined : JSON.stringify(value),
    ),
})

export const storage = {
  volume: jsonStorage<number>("volume"),
  muted: jsonStorage<boolean>("muted"),
  theme: stringStorage<ThemeName>("theme"),
  locale: stringStorage("locale"),
  token: stringStorage("token"),
  serial: stringStorage("serial"),
  uuid: stringStorage("uuid"),
  killSwitchTTL: jsonStorage<number>("killSwitchTTL"),
  lastTimeConnected: jsonStorage<number>("lastTimeConnected"),
  blacklistedTracks: jsonStorage<string[]>("blacklistedTracks"),
  listeningHistory: jsonStorage<ListeningHistory>("listeningHistory"),
  manifest: jsonStorage<Spectre.Manifest>("manifest"),
}
