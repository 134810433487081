import { USER } from "src/utils/constants"
import { Clock, SkipForward, X } from "react-feather"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "../state/redux/hooks"
import { playerActions } from "../state/redux/player.slice"
import { Modal } from "./Modal"
import { MenuButton } from "./MenuButton"
import { MenuTitle } from "./MenuTitle"

export function SetPlaylistModal({
  isOpen,
  onRequestClose,
  title,
  playlistId,
  onConfirm,
}: {
  isOpen: boolean
  onRequestClose: () => void
  title: string
  playlistId: string | undefined
  onConfirm?: () => void
}) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  return (
    <Modal {...{ isOpen, onRequestClose }}>
      <MenuTitle>{title}</MenuTitle>
      <MenuButton
        onClick={() => {
          onRequestClose()
          dispatch(
            playerActions.setManualPlaylist(playlistId, false, USER.PHYSICAL),
          )
        }}
      >
        <Clock />
        {t("Let current track finish")}
      </MenuButton>
      <MenuButton
        onClick={() => {
          onRequestClose()
          dispatch(
            playerActions.setManualPlaylist(playlistId, true, USER.PHYSICAL),
          )
        }}
      >
        <SkipForward />
        {t("Skip Current Track")}
      </MenuButton>

      <MenuButton onClick={() => onRequestClose()}>
        <X />
        {t("Cancel")}
      </MenuButton>
    </Modal>
  )
}
