import styled from "@emotion/styled"
import { ComponentProps } from "react"

type StyleProps = {
  m?: string | number
  mt?: string | number
  mb?: string | number
  ml?: string | number
  mr?: string | number
  mx?: string | number
  my?: string | number
  p?: string | number
  pt?: string | number
  pb?: string | number
  pl?: string | number
  pr?: string | number
  px?: string | number
  py?: string | number
}

const parseStyleProps = (props: StyleProps) => {
  const { m, mt, mb, ml, mr, mx, my, p, pt, pb, pl, pr, px, py } = props
  return {
    margin: m,
    marginTop: my ?? mt,
    marginBottom: my ?? mb,
    marginLeft: mx ?? ml,
    marginRight: mx ?? mr,
    padding: p,
    paddingTop: py ?? pt,
    paddingBottom: py ?? pb,
    paddingLeft: px ?? pl,
    paddingRight: px ?? pr,
  }
}

export const Box = styled.div<StyleProps>((props) => parseStyleProps(props))

export type BoxProps = ComponentProps<typeof Box>
