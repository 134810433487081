import { createSelector } from "@reduxjs/toolkit"
import { combinePaths, silenceUrl } from "../../utils/utils"
import { RootState } from "./store"

/**
 * TEMPORARY MANIFEST SELECTORS
 */
export const getProgramFromTemporaryManifest = (state: RootState) =>
  state.manifest.temp?.zones[0]

export const _getAllTrackIdsFromTemporaryManifestProgram = (
  zone?: Spectre.Zone,
): string[] => {
  const trackIds = zone?.musicProgram.playlists.flatMap(
    (playlist) => playlist.tracks,
  )
  // Keep distinct tracks only
  return Array.from(new Set(trackIds))
}

export const getBaseUrlFromTemporaryManifest = (state: RootState) =>
  getProgramFromTemporaryManifest(state)?.musicProgram?.baseUrl

export const getTrackUrlFromTemporaryManifest = (
  state: RootState,
  trackId: string,
) => {
  if (trackId === "silence") {
    return silenceUrl
  }
  const baseUrl = getBaseUrlFromTemporaryManifest(state)
  return baseUrl ? combinePaths(baseUrl, trackId) : null
}

export const getAllTrackIdsFromTemporaryManifest = createSelector(
  getProgramFromTemporaryManifest,
  _getAllTrackIdsFromTemporaryManifestProgram,
)

/**
 * FINAL MANIFEST SELECTORS
 */
export const getProgram = (state: RootState) => state.manifest.data?.zones[0]

export const getPlaylists = (state: RootState) =>
  getProgram(state)?.musicProgram.playlists || []

const getPlaylistsById = createSelector(getPlaylists, (playlists) => {
  const map: Record<string, Spectre.Playlist> = {}
  playlists?.forEach((playlist) => (map[playlist.id] = playlist))
  return map
})

export const getPlaylistById = (state: RootState, playlistId: string) =>
  getPlaylistsById(state)[playlistId]

/** Exported for tests */
export const _getAllTrackIdsFromProgram = (zone?: Spectre.Zone) => {
  const trackIds = zone?.musicProgram.playlists.flatMap(
    (playlist) => playlist.tracks,
  )
  // Keep distinct tracks only
  return Array.from(new Set(trackIds))
}

export const getBaseUrl = (state: RootState) =>
  getProgram(state)?.musicProgram?.baseUrl

export const getTrackUrl = (state: RootState, trackId: string) => {
  if (trackId === "silence") {
    return silenceUrl
  }
  const baseUrl = getBaseUrl(state)
  return baseUrl ? combinePaths(baseUrl, trackId) : null
}
