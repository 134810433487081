import type { RootState } from "./store"
import { getTracksStatus } from "./tracks.selectors"

// Application level selectors

export const appSelectors = {
  gotToken: (state: RootState) => {
    return (
      typeof state.auth.authToken === "string" &&
      state.auth.authToken.length > 0
    )
  },
  isReady: (state: RootState) => {
    return getTracksStatus(state) === "loaded"
  },
  isKillSwitchActivated: (state: RootState) => {
    return state.killSwitch.killSwitchActivated
  },
  isFirstSyncError: (state: RootState) => {
    return getTracksStatus(state) === "firstSyncError"
  },
  isBrowserNotSupported: (state: RootState) => {
    return state.device.isBrowserNotSupported
  },
}
