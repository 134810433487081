import { useAppSelector } from "../state/redux/hooks"
import { getNotifications } from "../state/redux/notifications.selectors"

export function Notifications() {
  const notifications = useAppSelector(getNotifications)

  return (
    <div>
      {notifications.map((notification) => (
        <div key={notification.id}>
          <p>{notification.message}</p>
        </div>
      ))}
    </div>
  )
}
