import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import { storage } from "./state/services/storage"
import { envConfig } from "./envConfig"

// Dynamically import all translations and build the resources object in the shape expected by i18next
const resources = Object.fromEntries(
  Object.entries(import.meta.glob("../locales/*/*.json", { eager: true })).map(
    ([path, content]) => {
      const [, , , , lang, ns] = path.split(/[/.]/)
      return [lang, { [ns]: (content as any).default }]
    },
  ),
)

export const setLocale = (locale: string) => {
  storage.locale.set(locale)
  i18next.changeLanguage(locale)

  window.intercomSettings = {
    app_id: envConfig.VITE_INTERCOM_APP_ID,
    alignment: "right",
    custom_launcher_selector: "#help",
    hide_default_launcher: true,
    language_override: locale,
  }

  window.Intercom("update")
}

export const toLanguage = (locale: string) => {
  return locale.split("-")[0]
}

i18next.use(initReactI18next).init({
  resources,
  lng: storage.locale.get() || navigator.language,
  fallbackLng: "en",
  interpolation: { escapeValue: false },
})
