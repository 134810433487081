import React from "react"
import clamp from "lodash/clamp"
import { useAnimationFrames } from "src/hooks/useAnimationFrames"
import { useEventListener } from "src/hooks/useEventListener"
import { useTheme } from "@emotion/react"
import { drawWaveform } from "./drawWaveform"
import { WaveformTheme } from "./WaveformTheme"
import {
  WaveformBars,
  getWaveformBars,
  getWaveformBarsTransitionStep,
  easeInOutCubic,
} from "./waveformUtils"

type WaveFormProps = Omit<React.HTMLProps<HTMLCanvasElement>, "data"> & {
  data: number[]
  ghostProgress?: number
  isPlaying?: boolean
  dataTransitionDuration?: number
  getProgress?(): number
}

const WaveForm = ({
  data,
  ghostProgress,
  getProgress,
  isPlaying,
  dataTransitionDuration,
  ...canvasProps
}: WaveFormProps) => {
  const theme = useTheme()
  const waveformTheme: WaveformTheme = {
    ghostOpacity: 0.2,

    bars: {
      width: 4,
      gap: 6,
      maxAmplitude: 1,
      radius: 99,
    },
    activeColors: {
      bars: theme.colors.accent,
    },
    offColors: {
      bars: theme.colors.text.ternary,
    },
  }

  const canvasRef = React.useRef<HTMLCanvasElement>(null)
  const currentBarsRef = React.useRef<WaveformBars>()
  // previousBars is used to animate transition between different data sets.
  const previousBarsTimeRef = React.useRef<number>()
  const previousBarsRef = React.useRef<WaveformBars>()

  // Return the bars that should be currently displayed
  const getDisplayedBars = () => {
    const previousBarsTime = previousBarsTimeRef.current
    const previousBars = previousBarsRef.current
    if (!currentBarsRef.current) {
      currentBarsRef.current =
        waveformTheme.bars && canvasRef.current
          ? getWaveformBars(
              data,
              waveformTheme.bars,
              canvasRef.current.offsetWidth,
            )
          : undefined
    }
    if (
      !(
        currentBarsRef.current &&
        dataTransitionDuration &&
        previousBars &&
        previousBarsTime
      )
    ) {
      return currentBarsRef.current
    }
    const t = clamp(
      (new Date().getTime() - previousBarsTime) / dataTransitionDuration,
      0,
      1,
    )
    if (t === 1) {
      previousBarsRef.current = undefined
      previousBarsTimeRef.current = undefined
    }
    return getWaveformBarsTransitionStep(
      previousBars,
      currentBarsRef.current,
      easeInOutCubic(t),
    )
  }

  const drawOnCanvas = () => {
    if (canvasRef.current) {
      drawWaveform({
        ghostProgress,
        theme: waveformTheme,
        canvas: canvasRef.current,
        bars: getDisplayedBars(),
        playbackProgress: getProgress?.(),
      })
    }
  }
  // clears bars so it will be re-calculated with new window size
  useEventListener("resize", () => {
    currentBarsRef.current = undefined
    drawOnCanvas()
  })

  // Animate when playing or transitioning bars
  const isAnimating =
    isPlaying || !!(dataTransitionDuration && previousBarsRef.current)
  useAnimationFrames(drawOnCanvas, isAnimating)

  // Clear cached bars when needed and keep the previous instance to animate transition
  React.useLayoutEffect(() => {
    previousBarsRef.current = getDisplayedBars()
    previousBarsTimeRef.current = new Date().getTime()
    currentBarsRef.current = undefined
  }, [data, waveformTheme.bars])

  React.useLayoutEffect(drawOnCanvas)

  return (
    <canvas
      ref={canvasRef}
      css={{
        height: "60px",
      }}
      {...canvasProps}
    />
  )
}

export default WaveForm
