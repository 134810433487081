import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { v4 as uuidv4 } from "uuid"
import log from "../../log"
import { getTimezone } from "../../utils/utils"
import { storage } from "../services/storage"
import type { AppThunk } from "./store"

export interface DeviceState {
  uuid?: string
  serial?: string
  model?: string
  deviceId?: string
  OS?: string
  OSVersion?: string
  appVersion?: string
  locale?: string
  country?: string
  timeZone?: string
  uptime?: string
  isBrowserNotSupported?: boolean
  newAppVersionAvailable?: boolean
  totalSpace?: number
  freeSpace?: number
}

const initialState: DeviceState = {
  uptime: new Date().toString(),
  uuid: undefined,
  isBrowserNotSupported: false,
  newAppVersionAvailable: false,
}

export const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    setAppVersion: (state, { payload }: PayloadAction<string>) => {
      state.appVersion = payload
    },
    getInfoSuccess: (state, { payload }: PayloadAction<DeviceState>) => {
      state.uuid = payload.uuid
      state.serial = payload.serial
      state.model = payload.model
      state.deviceId = payload.deviceId
      state.OS = payload.OS
      state.OSVersion = payload.OSVersion
      state.appVersion = payload.appVersion
      state.locale = payload.locale
      state.country = payload.country
      state.timeZone = payload.timeZone
      state.totalSpace = payload.totalSpace
      state.freeSpace = payload.freeSpace
    },
    setBrowserNotSupported: (state, { payload }: PayloadAction<boolean>) => {
      state.isBrowserNotSupported = payload
    },
    setNewAppVersionAvailable: (state, { payload }: PayloadAction<boolean>) => {
      state.newAppVersionAvailable = payload
    },
  },
})

const getSerialNumber = () => {
  // Get the serial number from the localStorage
  const serialNumber = storage.serial.get()
  if (serialNumber) {
    return serialNumber
  } else {
    // Generate a new serial number if none is found
    const newSerialNumber = uuidv4()
    storage.serial.set(newSerialNumber)
    return newSerialNumber
  }
}

export const getUniqueID = () => {
  // Get the unique id from the localStorage
  const uniqueId = storage.uuid.get()
  if (uniqueId) {
    return uniqueId
  } else {
    // Generate a new unique id if none is found
    const newUniqueId = uuidv4()
    storage.uuid.set(newUniqueId)
    return newUniqueId
  }
}

const populateDeviceInfo = (): AppThunk => async (dispatch, getState) => {
  log.debug("[Device][fetchInfo] Getting device info")
  const appVersion = getState().device.appVersion
  const uuid = getUniqueID()
  const serial = getSerialNumber()

  const browserSpec =
    navigator.userAgentData?.brands.find(
      (browserSpec: any) => browserSpec.brand === "Chromium",
    ) || navigator.userAgentData?.brands[0]

  const browserName = browserSpec?.brand || "Unknown"
  const browserVersion = browserSpec?.version || "Unknown"

  const isBrowserNotSupported = !(browserName === "Chromium")

  if (isBrowserNotSupported) {
    log.error(
      `[Device][fetchInfo] Browser not supported: ${browserName} ${browserVersion}`,
    )
    dispatch(deviceSlice.actions.setBrowserNotSupported(isBrowserNotSupported))
  }

  const storageSpace = await navigator.storage?.estimate()

  const totalSpace = storageSpace?.quota
  const usedSpace = storageSpace?.usage
  const freeSpace = totalSpace && usedSpace ? totalSpace - usedSpace : undefined

  log.debug(
    `[Device][fetchInfo] Device info: ${uuid} ${serial} ${browserName} ${browserVersion} ${appVersion} ${totalSpace} ${freeSpace}`,
  )

  dispatch(
    deviceSlice.actions.getInfoSuccess({
      uuid,
      serial,
      model: "Largo",
      deviceId: "deviceId",
      OS: browserName,
      OSVersion: browserVersion,
      appVersion,
      locale: "FR-fr",
      country: "France",
      timeZone: getTimezone(),
      totalSpace,
      freeSpace,
    }),
  )
}

const updateToNewVersion =
  (): AppThunk =>
  async (dispatch, getState, { serviceWorker }) => {
    log.info("[Device][updateToNewVersion] Updating to new version")
    if (serviceWorker) {
      await serviceWorker.updateSW()
      log.info("[Device][updateToNewVersion] Service worker updated")
    } else {
      log.warn(
        "[Device][updateToNewVersion] Service worker not available, cannot update",
      )
    }
  }

export const deviceActions = {
  populateDeviceInfo,
  updateToNewVersion,
  ...deviceSlice.actions,
}
