import styled from "@emotion/styled"
import { CSSProperties } from "react"
import { Box } from "./Box"

export const Grid = styled(Box)<{
  gap?: string
  columns?: string
  align?: CSSProperties["alignItems"]
}>(({ gap = ".5rem", columns, align }) => ({
  display: "grid",
  gap,
  gridTemplateColumns: columns,
  alignItems: align,
}))
