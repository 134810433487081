import * as Sentry from "@sentry/react"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { USER } from "src/utils/constants"
import { envConfig } from "src/envConfig"
import { getErrorMessage } from "../../utils/utils"
import log from "../../log"
import { storage } from "../services/storage"
import { deviceActions } from "./device.slice"
import { manifestActions } from "./manifest.slice"
import { getProgram } from "./manifest.selectors"
import { playerActions } from "./player.slice"
import { notificationsActions } from "./notifications.slice"
import { killSwitchActions } from "./killswitch.slice"
import type { AppThunk } from "./store"

export interface AuthState {
  authToken?: string
  pin?: string
  otherDeviceConnected: boolean
}

const initialState: AuthState = {
  authToken: storage.token.get(),
  otherDeviceConnected: false,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setNewAuthToken: (
      state,
      { payload }: PayloadAction<{ authToken: string }>,
    ) => {
      state.authToken = payload.authToken
      storage.token.set(payload.authToken)
    },
    setPin: (state, { payload }: PayloadAction<string>) => {
      state.pin = payload
    },
    setOtherDeviceConnected: (state, { payload }: PayloadAction<boolean>) => {
      state.otherDeviceConnected = payload
    },
  },
})

// Called when the app starts
const loginOnStart =
  (): AppThunk =>
  async (dispatch, getState, { socket }) => {
    try {
      // Get the device info here and once because it will be necessary later for the schedule, internationalization, etc...
      await dispatch(deviceActions.populateDeviceInfo())

      socket.setDeviceInfo(getState().device)

      // Get the token if already in the state (from the local storage)
      const authToken = getState().auth.authToken
      if (authToken) {
        log.info(`[Auth][loginOnStart] Found token`)
        await dispatch(connectWithLocalStorageToken(authToken))
      } else {
        await dispatch(connectWithSetupToken())
      }
    } catch (err) {
      const msg = getErrorMessage(err)
      log.error(`[Auth][loginOnStart] Socket connection failed: ${msg}`)
    }
  }

// Called when the auth token is not found in the local storage
const connectWithSetupToken =
  (): AppThunk =>
  async (dispatch, getState, { api, socket }) => {
    try {
      log.info(`[Auth][connectWithSetupToken] No token found`)
      const deviceInfo = getState().device

      if (!deviceInfo.uuid) {
        throw new Error(
          "[Auth][connectWithSetupToken] uuid not found in the device info",
        )
      }

      const setupToken = await api.getSetupToken(
        deviceInfo.uuid,
        envConfig.VITE_SPECTRE_API_KEY,
      )

      log.info("[Auth][connectWithSetupToken] Got new setup token from server")

      // Resolve the
      socket.firstLaunch(setupToken, {
        onDisconnect: () => {
          log.info(`[Auth][connectWithSetupToken] Socket disconnected`)
        },
        onReceiveToken: async (token: string) => {
          await dispatch(loginWithToken(token))
        },
        onTokenError: async (error: string) => {
          log.error(`[Auth][connectWithSetupToken] Token error: ${error}`)
          // Check it is a magic link activation and display a notification if yes
          const queryParams = getState().router.queryParams
          if (queryParams.activate === true) {
            dispatch(
              notificationsActions.showNotification(
                "Impossible to activate. Invalid token.",
                "error",
              ),
            )
          }
        },
        onSetupSuccess: async ({ token, pin }) => {
          log.info(`[Auth][connectWithSetupToken] Setup success`)
          if (token) {
            // already setup
            log.debug(
              "[Auth][connectWithSetupToken] Already setup, logging in with token",
            )
            dispatch(loginWithToken(token))
          } else {
            // not setup yet
            log.debug("[Auth][connectWithSetupToken] Not setup yet, saving pin")
            dispatch(authSlice.actions.setPin(pin))

            // Check if it is a magic link activation (activate is set to true in the url)
            const queryParams = getState().router.queryParams
            if (queryParams.activate === true) {
              dispatch(loginWithMagicLink())
            }
          }
        },
        onSetupError: async (error: string) => {
          log.error(`[Auth][connectWithSetupToken] Setup error: ${error}`)
          log.info(`[Auth][connectWithSetupToken] Staying offline`)
          dispatch(
            notificationsActions.showNotification(
              "Setup error. Staying offline.",
              "error",
            ),
          )
        },
        onUnauthorized: async (code: string) => {
          log.warn(`[Auth][connectWithSetupToken] Unauthorized: ${code}`)
          if (code === "uuid_already_connected") {
            dispatch(authSlice.actions.setOtherDeviceConnected(true))
          } else {
            // We consider that the device has been deleted
            dispatch(pairAgain("Device deleted"))
          }
        },
      })
    } catch (err) {
      const msg = getErrorMessage(err)
      dispatch(
        notificationsActions.showNotification(
          `Server unreachable, impossible to init the first connection. Maybe the player is offline.
          Please check your internet connection and try again.`,
          "error",
        ),
      )
      log.error(
        `[Auth][connectWithSetupToken] Socket connection failed: ${msg}`,
      )
    }
  }

// Called we already got an auth token
const connectWithLocalStorageToken =
  (token: string): AppThunk =>
  async (dispatch, getState, { socket }) => {
    log.info(`[Auth][connectWithLocalStorageToken] Connecting with token`)

    socket.connect(token, {
      onDisconnect: () => {
        log.info(`[Auth][connectWithLocalStorageToken] Socket disconnected`)
      },
      onReceivedCommand: async (command, param, username) => {
        log.info(
          `[Auth][connectWithLocalStorageToken] Received command ${command} from ${username}`,
        )
        if (command === "logout") {
          dispatch(pairAgain("Received Logout command"))
        } else {
          // we consider that the command is a player's command
          dispatch(playerActions.onReceiveCommand(command, param, username))
        }
      },
      onReceivedPacket: async (type: string) => {
        // Each time we received a ping or any message
        if (type === "ping" || type === "message") {
          // Update the kill switch last time connected
          dispatch(killSwitchActions.resetTTL())
        }
      },
      onReceivedManifest: async (manifest, fileCheck = false) => {
        dispatch(manifestActions.saveManifest(manifest, fileCheck))
      },
      onReceiveToken: async (token) => {
        await dispatch(loginWithToken(token))
      },
      onTokenError: async (errorMessage) => {
        log.error(
          `[Auth][connectWithLocalStorageToken] Token error: ${errorMessage}`,
        )
        Sentry.captureException(new Error(errorMessage))
      },
      onOptions: async (deviceOptions) => {
        log.info(
          `[Auth][connectWithLocalStorageToken][onOptions] Saving config & state: ttl=${deviceOptions.ttl} blacklist=${deviceOptions.blacklist}`,
        )

        await dispatch(
          playerActions.setBlacklistAndPersist(deviceOptions.blacklist),
        )

        await dispatch(killSwitchActions.setTTL(deviceOptions.ttl))
      },
      onDeviceAccepted: async (deviceOptions) => {
        log.info(
          `[Auth][connectWithLocalStorageToken][onDeviceAccepted] Saving config & state: ttl=${deviceOptions.ttl} blacklist=${deviceOptions.blacklist}`,
        )

        window.Intercom("boot", {
          app_id: envConfig.VITE_INTERCOM_APP_ID,
          user_id: getState().device.uuid,
        })

        await dispatch(
          playerActions.setBlacklistAndPersist(deviceOptions.blacklist),
        )

        await dispatch(killSwitchActions.setTTL(deviceOptions.ttl))

        if (!getState().manifest.data) {
          // Ask for the manifest after the first connection after the setup
          socket.send("manifest")
        } else {
          log.info(
            `[Auth][connectWithLocalStorageToken] Manifest already loaded`,
          )
          // Send the device's volume to vauxhall to update the volume in the dashboard on connect/reconnect
          dispatch(playerActions.sendVolumeToVauxhall("onDeviceAccepted"))

          // Resume the sync if a sync have not finish
          // This is the case when the player page is refreshed during a sync
          dispatch(manifestActions.resumeSyncIfNeeded())
        }
      },
      onReceiveZonesData: async (zones) => {
        log.info(
          `[Auth][connectWithLocalStorageToken] onReceiveZonesData`,
          zones,
        )
        const zoneId = getProgram(getState())?.zone.id
        if (!zoneId) {
          throw new Error(
            "[Player][connectWithLocalStorageToken] No zone found in the state. The manifest is probably not loaded or corrupted",
          )
        }
        const zone = zones[zoneId]
        if (!zone) {
          throw new Error(
            `[Player][connectWithLocalStorageToken] No zone found in the zones data for zoneId ${zoneId}`,
          )
        }
        dispatch(playerActions.onReceiveZoneData(zone))
      },
      onUnauthorized: async (code: string) => {
        log.warn(`[Auth][connectWithLocalStorageToken] Unauthorized: ${code}`)
        if (code === "uuid_already_connected") {
          dispatch(authSlice.actions.setOtherDeviceConnected(true))
          dispatch(playerActions.pause(USER.OTHER_DEVICE_CONNECTED))
        } else {
          // We consider that the device has been deleted
          dispatch(pairAgain("Device deleted"))
        }
      },
      onDeviceRejected: async (error: string) => {
        log.error(
          `[Auth][connectWithLocalStorageToken] Device rejected: ${error}`,
        )
        dispatch(pairAgain("Device deleted"))
      },
    })
  }

// Called when activate is set to true in the url
// This is used to activate a device with a magic link
const loginWithMagicLink =
  (): AppThunk =>
  async (dispatch, getState, { socket }) => {
    const { token, id } = getState().router.queryParams
    const uuid = getState().device.uuid

    if (!token || !id || !uuid) {
      log.warn(
        `[Auth][loginOnStart] Missing uuid, token or id in the query params`,
      )
      dispatch(
        notificationsActions.showNotification(
          "Impossible to activate. Missing data to authenticate.",
          "error",
        ),
      )
    } else {
      log.info(`[Auth][loginOnStart] Activating with magic link`)
      socket.sendSetup({
        uuid,
        token,
        deviceId: id,
      })
    }
  }

const loginWithToken =
  (authToken: string): AppThunk =>
  async (dispatch) => {
    log.info(
      "[Auth][loginWithToken] Saving the device's token to local storage",
    )
    await dispatch(authActions.setNewAuthToken({ authToken }))

    // Once the new token is saved
    // then force the page to reload
    // to re-init the app with a fresh state and a new authenticated socket connection
    window.location.reload()
  }

const logout =
  (reason: string): AppThunk =>
  async (dispatch, getState, { socket, tracksDB }) => {
    log.info(`[Auth][logout] Logging out: ${reason}`)

    // Send the logout event to store it in the History (in mongodb)
    socket.notifyLogout(reason)

    // Send the logout message to the server to delete the device from the database
    socket.send("logout")

    // Reset history and old state
    localStorage.removeItem("history")

    // Remove the token from the local storage
    localStorage.removeItem("token")

    // Remove the killSwitchTTL
    localStorage.removeItem("killSwitchTTL")

    // Remove the blacklistedTracks
    localStorage.removeItem("blacklistedTracks")

    // Remove the manifest but keep the tracks though
    localStorage.removeItem("manifest")

    // Clear the trackDB
    await tracksDB.clear()

    // Force the page to reload
    window.location.reload()
  }

const pairAgain =
  (reason: string): AppThunk =>
  async (dispatch) => {
    await dispatch(logout(reason))
  }

export const authActions = {
  loginOnStart,
  logout,
  ...authSlice.actions,
}
