import { WaveformBarsConfig } from "./WaveformTheme"

/** Data required to draw waveform bars */
export type WaveformBars = {
  /** max values for each bar, draws as bottom of the bars */
  values: number[]
  /** value is a fraction of total canvas width (0..1) */
  width: number
  /** value is a fraction of total canvas width (0..1) */
  gap: number
}

export const getWaveformBars = (
  data: number[],
  barsConfig: WaveformBarsConfig,
  canvasWidth: number,
): WaveformBars => {
  const bars: WaveformBars = {
    // convert theme px units to 0..1 values
    width: barsConfig.width / canvasWidth,
    gap: barsConfig.gap / canvasWidth,
    values: [],
  }

  const barsCount = Math.floor(
    Math.max(0, (1 + bars.gap) / (bars.width + bars.gap)),
  )
  const frameLength = data.length / barsCount

  for (let i = 0; i < barsCount; i += 1) {
    let start = Math.floor(i * frameLength)
    // makes sure we always have at least 2 values (for min/max)
    let end = start + Math.max(1, Math.round(frameLength))

    if (end > data.length) {
      start = Math.max(0, start - (end - data.length))
      end = data.length
    }

    // set of points for a given bar
    const frame = data.slice(start, end)

    // for each frame take the highest value to preserve picks
    const max = Math.max(0, ...frame.map((value) => Math.abs(value)))
    bars.values.push(max)
  }
  return bars
}

export const getNumberTransitionStep = (from: number, to: number, t: number) =>
  from + (to - from) * t

export const getNumbersTransitionStep = (
  from: number[],
  to: number[],
  t: number,
) => {
  const count = Math.max(from.length, to.length)
  return Array(count)
    .fill(0)
    .map((_, i) => getNumberTransitionStep(from[i] || 0, to[i] || 0, t))
}

export const getWaveformBarsTransitionStep = (
  barsFrom: WaveformBars | undefined,
  barsTo: WaveformBars | undefined,
  t: number,
): WaveformBars | undefined => {
  if (!barsFrom && !barsTo) {
    return undefined
  }
  return {
    ...(barsTo || barsFrom!),
    values: getNumbersTransitionStep(
      barsFrom ? barsFrom.values : [],
      barsTo ? barsTo.values : [],
      t,
    ),
  }
}

export const easeInOutCubic = (t: number) =>
  t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1

export const easingBackOut = (t: number) => {
  const s = 2

  return --t * t * ((s + 1) * t + s) + 1
}
