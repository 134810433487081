import { useMemo } from "react"
import { useAppDispatch } from "../state/redux/hooks"

/**
 * Returns the extra argument injected in redux thunk functions.
 * Allows easy access to our injected services from React components.
 */
export const useThunkExtraArgument = () => {
  const dispatch = useAppDispatch()
  return useMemo(() => dispatch((d, s, extra) => extra), [dispatch])
}

export const usePlayer = () => useThunkExtraArgument().player
