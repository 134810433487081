import { useTheme } from "@emotion/react"
import { SpectreLogo } from "../SpectreLogo"
import { Group } from "../lib/Group"
import { Box } from "../lib/Box"
import { PageSection } from "../lib/PageSection"
import { AutoPilotSwitch } from "../AutoPilotSwitch"
import { HeaderMenu } from "./HeaderMenu"

export function Header() {
  const theme = useTheme()

  return (
    <PageSection pt="2rem" css={{ background: theme.colors.bg.secondary }}>
      <Group>
        <SpectreLogo />
        <Box ml="auto" />
        <AutoPilotSwitch />
        <HeaderMenu />
      </Group>
    </PageSection>
  )
}
