import { Box, BoxProps } from "./Box"
import { Container } from "./Container"

export function PageSection({ children, ...props }: BoxProps) {
  return (
    <Box {...props}>
      <Container>{children}</Container>
    </Box>
  )
}
