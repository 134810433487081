import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import log from "../../log"
import { storage } from "../services/storage"
import type { AppThunk } from "./store"
import { isTTLExpired } from "./killswitch.selectors"
import { playerActions } from "./player.slice"

export interface KillSwitchState {
  ttl: number // Duration in days. 0 means the kill switch is disabled
  lastTimeConnected: number | undefined // Timestamp
  killSwitchActivated: boolean
}

const initialState: KillSwitchState = {
  ttl: storage.killSwitchTTL.get() ?? 0, // 0 means kill switch is disabled
  lastTimeConnected: storage.lastTimeConnected.get(),
  killSwitchActivated: false,
}

export const killSwitchSlice = createSlice({
  name: "killSwitch",
  initialState,
  reducers: {
    setTTL: (state, { payload }: PayloadAction<number>) => {
      state.ttl = payload
      // Save also the ttl in the local storage
      storage.killSwitchTTL.set(payload)
    },
    setLastTimeConnected: (state, { payload }: PayloadAction<number>) => {
      state.lastTimeConnected = payload
    },
    setKillSwitchActivated: (state, { payload }: PayloadAction<boolean>) => {
      state.killSwitchActivated = payload
    },
  },
})

const enablingKillSwitchIfTTLExpired =
  (): AppThunk => async (dispatch, getState) => {
    const expired = isTTLExpired(getState())
    if (expired) {
      log.warn("The app is offline since too long. Enabling the killSwitch")
      dispatch(killSwitchSlice.actions.setKillSwitchActivated(true))
      dispatch(playerActions.pause("killswitch"))
    } else {
      const lastTimeConnected = getState().killSwitch.lastTimeConnected
      if (lastTimeConnected && lastTimeConnected > 0) {
        log.info(
          `The app is online since ${new Date(
            lastTimeConnected,
          )}. Do not enable the killSwitch.`,
        )
      } else {
        log.debug(
          "No lastTimeConnected date stored. Do not enable the killSwitch.",
        )
      }
    }
  }

export const resetTTL = (): AppThunk => async (dispatch) => {
  const date = new Date()
  // Set the last time connected in the state and in the local storage
  dispatch(killSwitchSlice.actions.setLastTimeConnected(date.getTime()))
  storage.lastTimeConnected.set(date.getTime())
}

export const killSwitchActions = {
  enablingKillSwitchIfTTLExpired,
  resetTTL,
  ...killSwitchSlice.actions,
}
