import pLimit from "p-limit"
import * as Sentry from "@sentry/react"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import i18next from "i18next"
import log from "../../log"
import { silenceUrl, checkFileHash } from "../../utils/utils"
import { AppThunk, ThunkExtraArgument } from "./store"
import {
  getAllTrackIdsFromTemporaryManifest,
  getTrackUrlFromTemporaryManifest,
  getBaseUrlFromTemporaryManifest,
} from "./manifest.selectors"
import { notificationsActions } from "./notifications.slice"

export interface TracksState {
  syncState: "idle" | "syncing" | "synced" | "error"
  firstSync: boolean
  loadedCount: number
}

const initialState: TracksState = {
  syncState: "idle",
  firstSync: false,
  loadedCount: 0,
}

export const tracksSlice = createSlice({
  name: "tracks",
  initialState,
  reducers: {
    setLoadedCount: (state, { payload }: PayloadAction<number>) => {
      state.loadedCount = payload
    },
    incrementLoadedCount: (state) => {
      state.loadedCount++
    },
    setSyncState: (
      state,
      { payload }: PayloadAction<TracksState["syncState"]>,
    ) => {
      state.syncState = payload
    },
    setFirstSync: (state, { payload }: PayloadAction<boolean>) => {
      state.firstSync = payload
    },
  },
})

const initLoadedCount =
  (): AppThunk =>
  async (dispatch, getState, { tracksDB }) => {
    const storedUrls = await tracksDB.getAllUrls()
    const numberOfStoredTracks = storedUrls.filter(
      (url) => !url.endsWith("silence.m4a"),
    ).length
    dispatch(tracksSlice.actions.setLoadedCount(numberOfStoredTracks))
  }

// Download the file "silence" that is needed for the scheduler to work properly
const storeSilence =
  (): AppThunk =>
  async (dispatch, getState, { api, tracksDB }) => {
    try {
      const storedUrls = new Set(await tracksDB.getAllUrls())
      if (!storedUrls.has(silenceUrl)) {
        await tracksDB.add(silenceUrl, await api.downloadTrack(silenceUrl))
      }
    } catch (e) {
      log.error("Error while storing silence:", e)
      dispatch(
        notificationsActions.showNotificationWithTimeout(
          "Something wrong happened during the silence initialization",
          "error",
          30000,
        ),
      )
      Sentry.captureException(e)
    }
  }

const syncTracks =
  (fileCheck = false): AppThunk =>
  async (dispatch, getState, { api, tracksDB, socket }) => {
    // Check if there is a temporary manifest
    if (!getState().manifest.temp) {
      throw new Error("No temporary manifest. Sync aborted")
    }
    dispatch(tracksSlice.actions.setSyncState("syncing"))
    const startSync = new Date()
    const missingTracks: string[] = []
    const state = getState()
    // Lif of all the tracks that are in the manifest
    const allTrackIds = getAllTrackIdsFromTemporaryManifest(state)
    // List of all the urls of the manifest's tracks
    const allTracks = allTrackIds.map((id) => {
      const url = getTrackUrlFromTemporaryManifest(state, id)
      if (!url) {
        throw new Error(`No url built for track ${id}`)
      }
      return {
        id,
        url,
      }
    })
    const allUrls = allTracks.map((track) => track.url)

    try {
      // List the tracks that are already stored in local db
      // We use a Set to be able to use the efficient "has" method later
      const storedUrlsBeforeCheck = new Set(await tracksDB.getAllUrls())

      // If the number of stored tracks is 0 then it is the first sync
      if (
        Array.from(storedUrlsBeforeCheck).filter(
          (url) => !url.endsWith("silence.m4a"),
        ).length < 1
      ) {
        dispatch(tracksSlice.actions.setFirstSync(true))
      } else {
        dispatch(tracksSlice.actions.setFirstSync(false))
      }

      await socket.startSync(startSync, allUrls.length)

      if (fileCheck) {
        const baseUrl = getBaseUrlFromTemporaryManifest(state) || ""
        // List the tracks that are in the manifest and already stored in local db
        const tracksUrlToCheck = allUrls.filter((url) =>
          storedUrlsBeforeCheck.has(url),
        )
        // Limit the number of concurrent requests to the number of cores
        const limit = pLimit(navigator.hardwareConcurrency || 2)
        log.info(`Checking ${tracksUrlToCheck.length} tracks checksums...`)
        // For each track id then get the checksum from the server
        // and compare it with the checksum of the local file
        // If the checksums are different
        // then delete the corrupted file
        // display a warning in the console
        // and add it in the list of missing tracks
        await Promise.all(
          tracksUrlToCheck.map((url) =>
            limit(async () => {
              try {
                const checksum = await api.getTrackChecksum(url)
                const blob = await tracksDB.get(url)
                const isValid = await checkFileHash(url, blob, checksum)
                log.info(
                  `[Utils][checkFileHash] Hashed ${url.replace(baseUrl, "")}: ${
                    isValid ? "valid" : "invalid"
                  }`,
                )
                if (!isValid) {
                  log.warn(
                    `Corrupted track: ${url} (checksum: ${checksum}, size: ${blob.size})`,
                  )
                  await tracksDB.remove([url])
                  return url
                }
              } catch (e) {
                log.error(`Error while checking track ${url}:`, e)
              }
            }),
          ),
        )
      }

      const storedUrls = new Set(await tracksDB.getAllUrls())

      dispatch(
        tracksSlice.actions.setLoadedCount(
          allUrls.filter((url) => storedUrls.has(url)).length,
        ),
      )

      // Download missing tracks
      const toDownload = allUrls.filter((url) => !storedUrls.has(url))
      log.info(`Loading ${toDownload.length} tracks...`)

      // Download the files in sequence to reduce to the maximum the risk of failure with weak connections
      for (const index in toDownload) {
        const url = toDownload[index]
        try {
          const blob = await api.downloadTrack(url)
          try {
            await tracksDB.add(url, blob)
          } catch (e: any) {
            log.error(
              `[Tracks][syncTracks] Error while storing track ${url}:`,
              e,
            )
            if (e instanceof DOMException && e.name === "QuotaExceededError") {
              throw new Error(
                `QuotaExceededError: Impossible to store the track`,
              )
            }
          }
          dispatch(tracksSlice.actions.incrementLoadedCount())
          const trackId = allTracks.find((track) => track.url === url)?.id
          socket.syncProgress(
            startSync,
            parseInt(index),
            toDownload.length,
            trackId,
          )
        } catch (e) {
          if (
            e instanceof Error &&
            e.message === "QuotaExceededError: Impossible to store the track"
          ) {
            throw e
          }
          log.error(
            `[Tracks][syncTracks] Error while downloading track ${url}:`,
            e,
          )
          missingTracks.push(url)
        }
      }

      // Validate that the number of missing tracks is inferior to 5%
      const missingTracksThreshold = Math.ceil(allUrls.length * 0.05)
      if (missingTracks.length > missingTracksThreshold) {
        throw new Error(
          `Too many tracks missing (${missingTracks.length} missing tracks). Abort the sync`,
        )
      }

      // Delete tracks that are not in the manifest
      await tracksDB.remove(
        Array.from(storedUrls).filter(
          (trackUrl) =>
            !trackUrl.endsWith("silence.m4a") && !allUrls.includes(trackUrl),
        ),
      )

      dispatch(tracksSlice.actions.setSyncState("synced"))
      await socket.endSync(startSync, missingTracks, allUrls.length)
    } catch (e: any) {
      log.error("Error while loading tracks:", e)
      dispatch(tracksSlice.actions.setSyncState("error"))
      // We notify the server that the sync is finished
      // whether it is a success or a failure
      // In the vauxhall server we will check if the number of missing tracks is inferior to the threshold
      // If it is the case then it will consider the sync as a success
      await socket.endSync(startSync, missingTracks, allUrls.length)
      if (e.message.includes("QuotaExceededError")) {
        dispatch(
          notificationsActions.showNotificationWithTimeout(
            i18next.t(
              "The browser's storage quota is insufficient to complete the download. Please free some room on your computer.",
            ),
            "error",
            30000,
          ),
        )
      } else {
        dispatch(
          notificationsActions.showNotificationWithTimeout(
            "Something wrong happened during the track list synchronization",
            "error",
            30000,
          ),
        )
      }
      Sentry.captureException(e)
      // We have to throw the error to be able
      // to catch it in the saveManifest thunk
      throw e
    }
  }

export type Track = Awaited<ReturnType<typeof getLoadedTrackByUrl>>

export async function getLoadedTrackByUrl(
  url: string,
  {
    tracksDB,
    trackParser,
  }: Pick<ThunkExtraArgument, "trackParser" | "tracksDB">,
) {
  const blob = await tracksDB.get(url)

  const audioBuffer = await trackParser.decodeAudioData(blob)

  // TODO: optimize so that we don't need to analyze the blob every times the track is played
  const waveform = trackParser.getWaveformData(audioBuffer)
  const metadata = await trackParser.readMetadata(blob)

  return {
    ...metadata,
    audioBuffer,
    waveform,
  }
}

export const tracksActions = {
  syncTracks,
  storeSilence,
  initLoadedCount,
  ...tracksSlice.actions,
}
