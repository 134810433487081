import { useCollapse } from "react-collapsed"
import { Box, BoxProps } from "./Box"

export function Collapse({
  in: isIn,
  ...boxProps
}: BoxProps & { in: boolean }) {
  const { getCollapseProps } = useCollapse({ isExpanded: isIn })

  return <Box {...boxProps} {...getCollapseProps()}></Box>
}
