import { deviceActions } from "../state/redux/device.slice"
import { useAppSelector, useAppDispatch } from "../state/redux/hooks"

export function UpdateServiceWorkerPrompt() {
  const dispatch = useAppDispatch()

  const isNewVersionAvailable = useAppSelector(
    (state) => state.device.newAppVersionAvailable,
  )

  // dispatch an action to update the service worker
  const updateServiceWorker = () => {
    dispatch(deviceActions.updateToNewVersion())
  }

  return (
    <>
      {isNewVersionAvailable && (
        <div>
          New version available.
          <button data-test="updateServiceWorker" onClick={updateServiceWorker}>
            Update the app
          </button>
        </div>
      )}
    </>
  )
}
