import { Player } from "src/components/player/Player"

import { Header } from "src/components/Header/Header"
import { Text } from "src/components/lib/Text"
import { useDispatch } from "react-redux"
import { Collapse } from "src/components/lib/Collapse"
import { getManualPlaylist } from "src/state/redux/player.selectors"
import { useTranslation } from "react-i18next"
import { PageSection } from "src/components/lib/PageSection"
import { Link } from "src/components/lib/Link"
import { SetPlaylistModal } from "src/components/SetManualPlaylistModal"
import { playerActions } from "src/state/redux/player.slice"
import { useTheme } from "@emotion/react"
import { useAppSelector } from "../state/redux/hooks"
import { PlaylistsDrawer } from "../components/PlaylistsDrawer/PlaylistsDrawer"

export function PlayerPage() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const manualPlaylist = useAppSelector(getManualPlaylist)
  const { t } = useTranslation()
  const isResumeScheduleConfirmOpen = useAppSelector(
    ({ player }) => player.isResumeScheduleConfirmOpen,
  )

  return (
    <>
      <SetPlaylistModal
        isOpen={isResumeScheduleConfirmOpen}
        title={t("Return to scheduled program")}
        onRequestClose={() =>
          dispatch(playerActions.setIsResumeScheduleConfirmOpen(false))
        }
        playlistId={undefined}
      />
      <Collapse in={!!manualPlaylist}>
        <PageSection
          css={{ background: theme.colors.accent, color: theme.colors.white }}
          p="1rem"
        >
          <Text size="md" weight="medium">
            {t("Now playing {{playlist}}, resuming schedule at 3am.", {
              playlist: manualPlaylist?.name,
            })}{" "}
            <Link
              onClick={() =>
                dispatch(playerActions.setIsResumeScheduleConfirmOpen(true))
              }
            >
              {t("Resume now")}
            </Link>
          </Text>
        </PageSection>
      </Collapse>
      <Header />
      <Player />
      <PlaylistsDrawer />
    </>
  )
}
