import React from "react"
import ReactDOM from "react-dom/client"
import * as Sentry from "@sentry/react"
import { datadogLogs } from "@datadog/browser-logs"
import { Provider } from "react-redux"
import ReactModal from "react-modal"
import { ThemeProvider } from "@emotion/react"
import { reportWebVitals } from "./reportWebVitals"
import { Router } from "./Router"
import { Notifications } from "./components/Notifications"
import { UpdateServiceWorkerPrompt } from "./components/UpdateServiceWorkerPrompt"
import { Footer } from "./components/Footer"
import { createSpectreStore } from "./state/redux/store"
import { spectreApi } from "./state/services/api"
import { tracksActions } from "./state/redux/tracks.slice"
import { createAudioPlayer } from "./state/services/audioPlayer"
import { tracksDB } from "./state/services/tracksDB"
import { trackParser } from "./state/services/trackParser"
import { authActions } from "./state/redux/auth.slice"
import { routerActions } from "./state/redux/router.slice"
import { scheduler } from "./state/services/scheduler"
import { createSocketManager } from "./state/services/socket"
import { deviceActions, getUniqueID } from "./state/redux/device.slice"
import { playerActions } from "./state/redux/player.slice"
import { themeDark, themeLight } from "./styles/theme"
import "./i18n"
import { GlobalCss } from "./styles/GlobalCss"
import { useAppSelector } from "./state/redux/hooks"
import { registerServiceWorker } from "./registerServiceWorker"
import log from "./log"
import { envConfig } from "./envConfig"
import { Box } from "./components/lib/Box"

const mode = envConfig.MODE
const uuid = getUniqueID()
if (mode === "staging" || mode === "production") {
  Sentry.init({
    dsn: "https://aecc3f870012e97c8f656e104c635b99@o131802.ingest.sentry.io/4506072831819776",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/vauxhall\.spectre-music\.com/,
        ],
      }),
    ],
    release: envConfig.APP_VERSION,
    environment: mode,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
  })
  Sentry.setTag("uuid", uuid)
  Sentry.setUser({ id: uuid })
}

log.info(`App version: ${envConfig.APP_VERSION}`)

if (["staging", "production"].includes(mode) && envConfig.VITE_DATADOG_TOKEN) {
  datadogLogs.init({
    clientToken: envConfig.VITE_DATADOG_TOKEN,
    site: envConfig.VITE_DATADOG_SITE,
    service: envConfig.VITE_DATADOG_SERVICE,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    forwardConsoleLogs: ["info", "warn", "error"],
    env: mode,
    version: envConfig.APP_VERSION,
  })
  datadogLogs.setGlobalContextProperty("uuid", uuid)
}

// Init the service worker
const serviceWorker = registerServiceWorker()

// Create the store and kick off the sync right away!
const player = createAudioPlayer((action) => store?.dispatch(action))
const socket = createSocketManager()
const store = createSpectreStore({
  extraArgument: {
    api: spectreApi,
    player,
    tracksDB,
    trackParser,
    scheduler,
    socket,
    serviceWorker,
  },
})

serviceWorker.onNewVersionAvailable(() => {
  store.dispatch(deviceActions.setNewAppVersionAvailable(true))
})

store.dispatch(playerActions.initPlayer())

// @ts-ignore
if (window.Cypress) {
  // This hack exposes the store when running Cypress tests
  // Very handy for testing!
  // @ts-ignore
  window.store = store
}

// Store the silence file in indexDB
store.dispatch(tracksActions.storeSilence())

// Add the redux state to Sentry error reports
Sentry.addGlobalEventProcessor((event, hint) => {
  const fullStore = store.getState()
  // Filter out the listening history to avoid sending too much data
  const filteredStore = {
    ...fullStore,
    player: {
      ...fullStore?.player,
      // Keep only the last 10 tracks
      listeningHistory: fullStore?.player?.listeningHistory?.slice(0, 10),
    },
  }
  hint.attachments = [
    { filename: "redux-state.txt", data: JSON.stringify(filteredStore) },
  ]
  return event
})

// Set the query params in the store
store.dispatch(routerActions.extractTheQueryParams())
store.dispatch(deviceActions.setAppVersion(envConfig.APP_VERSION))
// Login
store.dispatch(authActions.loginOnStart())

// Manage the socket disconnection when the user close the page
window.onbeforeunload = function () {
  socket.disconnect()
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

const App = () => {
  window.Intercom("update")

  const themeName = useAppSelector((state) => state.app.themeName)

  return (
    <ThemeProvider theme={themeName == "dark" ? themeDark : themeLight}>
      <GlobalCss />
      <UpdateServiceWorkerPrompt />
      <Notifications />
      <Box
        css={{ minHeight: "100svh", display: "flex", flexDirection: "column" }}
      >
        <Router />
        <Footer />
      </Box>
    </ThemeProvider>
  )
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
)
ReactModal.setAppElement("#root")

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
