import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import type { ThemeName } from "src/styles/theme"
import { storage } from "../services/storage"

export interface AppState {
  themeName: ThemeName
}

const initialState: AppState = {
  themeName:
    storage.theme.get() ||
    (window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light"),
}

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setThemeName: (state, { payload }: PayloadAction<ThemeName>) => {
      state.themeName = payload
      storage.theme.set(payload)
    },
  },
})

export const appActions = appSlice.actions
