import jsmediatags from "jsmediatags/dist/jsmediatags.min"
import type { TagType } from "jsmediatags/types"
import { audioContext } from "./audioPlayer"

// For some reason, metas come in simple quotes: "'meta'""
const cleanupMeta = (s?: string) => s?.replace(/(^'|'$)/g, "")

const readMetadata = async (trackBlob: Blob) => {
  const meta = await new Promise<TagType>((resolve, reject) => {
    jsmediatags.read(trackBlob, {
      onSuccess: function (tag) {
        resolve(tag)
      },
      onError: function (error) {
        reject(error)
      },
    })
  })
  const { title, artist, album, picture } = meta.tags
  return {
    title: cleanupMeta(title),
    artist: cleanupMeta(artist),
    album: cleanupMeta(album),
    format: meta.type,
    pictureUrl:
      picture &&
      URL.createObjectURL(
        new Blob([new Uint8Array(picture.data).buffer], {
          type: picture.format,
        }),
      ),
  }
}

const normalizeWaveformData = (data: number[]) => {
  const max = Math.max(...data.map((n) => Math.abs(n)))
  return data.map((n) => n / max)
}

const decodeAudioData = (audioBlob: Blob): Promise<AudioBuffer> => {
  return new Promise((resolve, reject) => {
    try {
      const fileReader = new FileReader()

      fileReader.onloadend = () => {
        try {
          const arrayBuffer = fileReader.result

          if (!arrayBuffer || !(arrayBuffer instanceof ArrayBuffer)) {
            throw new Error("[decodeAudioData] Error reading file")
          }

          audioContext.decodeAudioData(arrayBuffer).then((audioBuffer) => {
            resolve(audioBuffer)
          })
        } catch (error) {
          reject(error)
        }
      }

      fileReader.onerror = reject

      fileReader.readAsArrayBuffer(audioBlob)
    } catch (error) {
      reject(error)
    }
  })
}

function getWaveformData(audioBuffer: AudioBuffer) {
  const rawData = audioBuffer.getChannelData(0)
  const samples = 1000 // Adjust for waveform resolution
  const blockSize = Math.floor(rawData.length / samples)
  const waveform: number[] = []

  for (let i = 0; i < samples; i++) {
    const blockStart = blockSize * i
    let sum = 0
    for (let j = 0; j < blockSize; j++) {
      sum += Math.abs(rawData[blockStart + j])
    }
    waveform.push(sum / blockSize)
  }

  return normalizeWaveformData(waveform)
}

export const trackParser = {
  readMetadata: readMetadata,
  getWaveformData,
  decodeAudioData,
}

export type TrackParser = typeof trackParser
