import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppThunk } from "./store"

type Notification = { id: string; message: string; level: "error" | "warning" }

export interface NotificationsState {
  notifications: Notification[]
}

const initialState: NotificationsState = {
  notifications: [],
}

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications: (state, { payload }: PayloadAction<Notification>) => {
      state.notifications.push(payload)
    },
    removeNotification: (state, { payload }: PayloadAction<string>) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== payload,
      )
    },
  },
})

const showNotificationWithTimeout =
  (
    message: string,
    level: "error" | "warning",
    timeoutInSeconds = 10,
  ): AppThunk =>
  async (dispatch) => {
    const id = Math.random().toString()
    dispatch(
      notificationsSlice.actions.setNotifications({ id, message, level }),
    )
    setTimeout(() => {
      dispatch(notificationsSlice.actions.removeNotification(id))
    }, timeoutInSeconds * 1000)
  }

const showNotification =
  (message: string, level: "error" | "warning"): AppThunk =>
  async (dispatch) => {
    const id = Math.random().toString()
    dispatch(
      notificationsSlice.actions.setNotifications({ id, message, level }),
    )
  }

export const notificationsActions = {
  showNotification,
  showNotificationWithTimeout,
  ...notificationsSlice.actions,
}
