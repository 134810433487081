import { getPlaylistById } from "./manifest.selectors"
import type { RootState } from "./store"

export const getCurrentPlaylist = (state: RootState) => {
  const { programPlaylistId } = state.player
  if (!programPlaylistId) return undefined
  return getPlaylistById(state, programPlaylistId)
}

export const getManualPlaylist = (state: RootState) => {
  const { manualPlaylistId } = state.player
  return manualPlaylistId ? getPlaylistById(state, manualPlaylistId) : undefined
}

export const getCurrentTrack = (state: RootState) => {
  return state.player.loadedTrack
}

export const getIsPlayingTrack = (state: RootState) =>
  state.player.trackId !== "silence" && state.player.playerStatus?.isPlaying

export const getIsAutoPilot = (state: RootState) =>
  !state.player.manualPlaylistId

export const getIsMuted = (state: RootState) =>
  state.player.playerStatus.volume === 0
