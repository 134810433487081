import { FirstLaunchSyncError } from "./pages/FirstLaunchSyncError"
import { FirstLoadPage } from "./pages/FirstLoadPage"
import { LoadingPage } from "./pages/LoadingPage"
import { NotSupportedBrowserPage } from "./pages/NotSupportedBrowserPage"
import { PlayerPage } from "./pages/PlayerPage"
import { KillSwitchPage } from "./pages/KillSwitchPage"
import { AlreadyConnected } from "./pages/AlreadyConnected"
import { useAppSelector } from "./state/redux/hooks"
import { getCurrentPageId } from "./state/redux/router.selectors"

export function Router() {
  const pageId = useAppSelector(getCurrentPageId)
  switch (pageId) {
    case "firstSyncError":
      return <FirstLaunchSyncError />
    case "browserNotSupported":
      return <NotSupportedBrowserPage />
    case "firstLoad":
      return <FirstLoadPage />
    case "player":
      return <PlayerPage />
    case "killSwitch":
      return <KillSwitchPage />
    case "otherDeviceConnected":
      return <AlreadyConnected />
    default:
      return <LoadingPage />
  }
}
