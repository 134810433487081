export function AlreadyConnected() {
  return (
    <div>
      A device is already connected with for the same player uuid. Please
      disconnect it and try again.
      <br />
      <button onClick={() => window.location.reload()}>Retry</button>
    </div>
  )
}
