import React from "react"

export function useEventListener<K extends keyof WindowEventMap>(
  eventKey: K,
  callback: (event: WindowEventMap[K]) => any,
  /** will register event handler only if isActive=true */
  isActive?: boolean,
): void

export function useEventListener<K extends keyof DocumentEventMap>(
  eventKey: K,
  callback: (event: DocumentEventMap[K]) => any,
  /** will register event handler only if isActive=true */
  isActive?: boolean,
  target?: Document,
): void

export function useEventListener<T extends Event>(
  eventKey: string,
  callback: (event: T) => any,
  /** will register event handler only if isActive=true */
  isActive?: boolean,
  target?: EventTarget,
): void

export function useEventListener(
  eventKey: string,
  callback: (event: Event) => any,
  /** will register event handler only if isActive=true */
  isActive = true,
  target: EventTarget | false = typeof window !== "undefined" && window,
) {
  // Store callback in a ref so we don't have to remove/add the event listener when callback changes
  const callbackRef = React.useRef(callback)
  callbackRef.current = callback

  React.useEffect(() => {
    if (!isActive || !target) {
      return
    }
    const handler = (event: Event) => callbackRef.current(event)
    target.addEventListener(eventKey, handler)
    return () => {
      target.removeEventListener(eventKey, handler)
    }
  }, [target, eventKey, isActive])
}
