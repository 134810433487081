import { useAppDispatch, useAppSelector } from "src/state/redux/hooks"
import { USER } from "src/utils/constants"
import { authActions } from "src/state/redux/auth.slice"
import { useTheme } from "@emotion/react"
import { useTranslation } from "react-i18next"
import { setLocale, toLanguage } from "src/i18n"
import {
  CheckCircle,
  Circle,
  HelpCircle,
  LogOut,
  Moon,
  MoreHorizontal,
  Sun,
  X,
} from "react-feather"
import { appActions } from "src/state/redux/app.slice"
import { useState } from "react"
import { useClickOutside } from "src/hooks/useClickOutside"
import { Box } from "../lib/Box"
import { Button } from "../lib/Button"
import { Text } from "../lib/Text"
import languages from "../../../locales/languages.json"
import { MenuButton } from "../MenuButton"
import { MenuTitle } from "../MenuTitle"
import { Modal } from "../Modal"

export function HeaderMenu() {
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [isLogoutRequested, setIsLogoutRequested] = useState(false)
  const theme = useTheme()
  const { i18n } = useTranslation()
  const language = toLanguage(i18n.language)
  const { t } = useTranslation()
  const themeName = useAppSelector((state) => state.app.themeName)
  const rootRef = useClickOutside(() => setIsOpen(false), isOpen)

  return (
    <>
      <Modal
        isOpen={isLogoutRequested}
        onRequestClose={() => setIsLogoutRequested(false)}
      >
        <MenuTitle>{t("Do you really want to log out?")}</MenuTitle>
        <MenuButton onClick={() => setIsLogoutRequested(false)}>
          <X />
          {t("Cancel")}
        </MenuButton>
        <MenuButton onClick={() => dispatch(authActions.logout(USER.PHYSICAL))}>
          <LogOut />
          {t("Log out")}
        </MenuButton>
      </Modal>
      <Box ref={rootRef} css={{ position: "relative" }}>
        <Button onClick={() => setIsOpen(!isOpen)} aria-label={t("Menu")} ball>
          <MoreHorizontal />
        </Button>
        <Box
          css={{
            border: theme.border.subtle,
            position: "absolute",
            overflow: "hidden",
            bottom: 0,
            transform: "translateY(calc(100% + 1rem))",
            right: 0,
            background: theme.colors.bg.primary,
            zIndex: 1,
            width: 200,
            borderRadius: theme.radius.lg,
            boxShadow: theme.shadows.lg,
            display: "grid",
            transition: "all .2s cubic-bezier(0.6, 0.3, 0, 1)",
            ...(!isOpen && {
              opacity: 0,
              pointerEvents: "none",
              transform: `translateY(calc(100% - 1rem)) scale(0.8)`,
              transformOrigin: "top center",
            }),
          }}
        >
          <MenuButton onClick={() => alert("todo")} aria-label={t("Help")}>
            <HelpCircle />
            {t("Help")}
          </MenuButton>
          <MenuButton
            onClick={() =>
              dispatch(
                appActions.setThemeName(
                  themeName === "dark" ? "light" : "dark",
                ),
              )
            }
          >
            {themeName === "dark" ? <Sun /> : <Moon />}
            {themeName === "dark"
              ? t("Turn On the Lights")
              : t("Turn Off the Lights")}
          </MenuButton>
          <MenuButton onClick={() => setIsLogoutRequested(true)}>
            <LogOut style={{ position: "relative", left: 2 }} />
            {t("Log out")}
          </MenuButton>
          <Text
            p="1.5rem 1rem .5rem 1rem"
            pb=".5rem"
            color="secondary"
            size="sm"
            weight="medium"
            css={{ borderBottom: theme.border.subtle }}
          >
            {t("LANGUAGE")}
          </Text>
          {languages.map((lang) => (
            <MenuButton key={lang.code} onClick={() => setLocale(lang.code)}>
              {language === lang.code ? <CheckCircle /> : <Circle />}
              {lang.name}
            </MenuButton>
          ))}
        </Box>
      </Box>
    </>
  )
}
