import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit"
import { serviceWorkerType } from "../../registerServiceWorker"
import { SpectreApi } from "../services/api"
import { AudioPlayer } from "../services/audioPlayer"
import { TrackParser } from "../services/trackParser"
import { Scheduler } from "../services/scheduler"
import { TracksDB } from "../services/tracksDB"
import { SocketManagerType } from "../services/socket"
import { manifestSlice } from "./manifest.slice"
import { playerSlice } from "./player.slice"
import { routerSlice } from "./router.slice"
import { tracksSlice } from "./tracks.slice"
import { deviceSlice } from "./device.slice"
import { authSlice } from "./auth.slice"
import { killSwitchSlice } from "./killswitch.slice"
import { notificationsSlice } from "./notifications.slice"
import { appSlice } from "./app.slice"

export type ThunkExtraArgument = {
  api: SpectreApi
  player: AudioPlayer
  tracksDB: TracksDB
  trackParser: TrackParser
  scheduler: Scheduler
  socket: SocketManagerType
  serviceWorker: serviceWorkerType
}

export const reducer = combineReducers({
  tracks: tracksSlice.reducer,
  player: playerSlice.reducer,
  manifest: manifestSlice.reducer,
  router: routerSlice.reducer,
  auth: authSlice.reducer,
  device: deviceSlice.reducer,
  killSwitch: killSwitchSlice.reducer,
  notifications: notificationsSlice.reducer,
  app: appSlice.reducer,
})

export const createSpectreStore = ({
  extraArgument,
  preloadedState,
}: {
  extraArgument: ThunkExtraArgument
  preloadedState?: RootState
}) => {
  return configureStore({
    reducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: { extraArgument },
      }),
  })
}

export type AppStore = ReturnType<typeof createSpectreStore>

export type AppDispatch = AppStore["dispatch"]
export type RootState = ReturnType<typeof reducer>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  ThunkExtraArgument,
  Action<string>
>
