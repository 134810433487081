import "@emotion/react"

import { CSSProperties } from "react"

export const pallette = {
  accent: "#BC2F2C",
}

const font = {
  family: {
    karelia: "Karelia",
    untitledSans: "Untitled Sans",
  },
  weight: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
} as const

export const themeLight = {
  isDark: false,
  colors: {
    accent: pallette.accent,
    text: {
      accent: pallette.accent,
      hover: "#000",
      primary: "#000000dd",
      secondary: "#00000077",
      ternary: "#B2B2B6",
    },
    bg: {
      primary: "#FFFFFF",
      secondary: "#F9F9F9",
      hover: "#00000008",
      modal: "#000000cc",
    },
    /** Use explicit colors only when it should remain the same color regardless of the theme. */
    white: "#FFFFFF",
    /** Use explicit colors only when it should remain the same color regardless of the theme. */
    black: "#000000",
  },
  border: {
    subtle: "1px solid #00000012",
    full: "1px solid #000000",
  },
  text: {
    xs: { fontSize: "13px" },
    sm: { fontSize: "14px" },
    md: { fontSize: "18px" },
    lg: { fontSize: "20px" },
    xxl: { fontSize: "70px", lineHeight: 1.15 },
  } satisfies { [key: string]: CSSProperties },
  font,
  radius: {
    md: 8,
    lg: 12,
    full: 9999,
  },
  shadows: {
    md: "0 1px .5rem rgba(0, 0, 0, 0.2)",
    lg: "0 2px 1rem rgba(0, 0, 0, 0.2)",
  },
  maxContentWidth: "1200px",
  zIndex: {
    drawers: 100,
    modals: 200,
  },
}

export const themeDark: SpectreTheme = {
  ...themeLight,
  isDark: true,
  colors: {
    ...themeLight.colors,
    text: {
      primary: "#fff",
      hover: "#fff",
      accent: pallette.accent,
      secondary: "#ffffff77",
      ternary: "#ffffff55",
    },
    bg: {
      primary: "#1E1E1E",
      secondary: "#000",
      hover: "#ffffff11",
      modal: "#000000cc",
    },
  },
  border: {
    subtle: "1px solid #ffffff20",
    full: "1px solid #fff",
  },
} as const

export type SpectreTheme = typeof themeLight
export type TextColor = keyof SpectreTheme["colors"]["text"]
export type ThemeFontSize = keyof SpectreTheme["text"]
export type FontWeight = keyof SpectreTheme["font"]["weight"]
export type FontFamily = keyof SpectreTheme["font"]["family"]

declare module "@emotion/react" {
  export interface Theme extends SpectreTheme {}
}
export const themeNames = ["light", "dark"] as const
export type ThemeName = (typeof themeNames)[number]
