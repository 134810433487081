import { useState } from "react"
import { useAnimationFrames } from "../hooks/useAnimationFrames"
import { usePlayer } from "../hooks/useThunkExtraArgument"
import { formatTime } from "../utils/utils"
import { Text, TextProps } from "./lib/Text"

export function PlayerProgress({
  remaining,
  ...textProps
}: { remaining?: boolean } & TextProps) {
  const [position, setPosition] = useState(0)
  const [duration, setDuration] = useState(0)
  const player = usePlayer()
  useAnimationFrames(() => {
    setPosition(Math.floor(player.getPosition() ?? 0))
    setDuration(Math.floor(player.getDuration() ?? 0))
  })

  const time = remaining ? position - duration : position

  return (
    <Text {...textProps} css={{ minWidth: 40 }}>
      <span>{formatTime(time * 1000)}</span>
    </Text>
  )
}
