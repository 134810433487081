import { useTheme } from "@emotion/react"
import { useAppDispatch, useAppSelector } from "src/state/redux/hooks"
import { playerActions } from "src/state/redux/player.slice"
import { useTranslation } from "react-i18next"
import { ToggleLeft } from "react-feather"
import { Button } from "./lib/Button"

export function AutoPilotSwitch() {
  const isAutoPilot = useAppSelector(({ player }) => !player.manualPlaylistId)
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <>
      <Button
        primary={isAutoPilot}
        css={{
          display: "flex",
          gap: ".5rem",
          // handle of the switch
          circle: {
            transform: isAutoPilot ? "translateX(.5rem)" : undefined,
          },
          "&, *": {
            transition: "all .2s ease",
            // we cannot rely on currentColor because the transition would not work as expected
            color: isAutoPilot ? theme.colors.white : theme.colors.text.ternary,
          },
          "&:hover, &:hover *": {
            color: isAutoPilot ? theme.colors.white : theme.colors.text.primary,
          },
        }}
        onClick={() => {
          dispatch(
            isAutoPilot
              ? playerActions.setIsPlaylistSelectOpen(true)
              : playerActions.setIsResumeScheduleConfirmOpen(true),
          )
        }}
      >
        <ToggleLeft />
        {t("AUTOPILOT")}
      </Button>
    </>
  )
}
