import { appSelectors } from "./app.selectors"
import { RootState } from "./store"
import type { PageId } from "./router.slice"

export const getCurrentPageId = (state: RootState): PageId => {
  const otherDeviceConnected = state.auth.otherDeviceConnected
  if (otherDeviceConnected) {
    return "otherDeviceConnected"
  } else if (appSelectors.isKillSwitchActivated(state)) {
    return "killSwitch"
  } else if (appSelectors.isBrowserNotSupported(state)) {
    return "browserNotSupported"
  } else if (!appSelectors.gotToken(state)) {
    return "firstLoad"
  } else if (appSelectors.isReady(state)) {
    return state.router.currentPageId
  } else if (appSelectors.isFirstSyncError(state)) {
    return "firstSyncError"
  } else {
    return "loading"
  }
}
