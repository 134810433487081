import throttle from "lodash/throttle"

import { playerActions } from "../redux/player.slice"
import log from "../../log"

export const crossfadeDuration = 3000 //ms

export type PlayerStatus = {
  isPlaying: boolean
  volume: number
}

// we need to instantiate and export the analyser here to be able to use it in the PlayingIcon component
// that's a bit hacky, it works but ideally I would like to find a way to access it from the redux services
// the problem is that the analyser is not serializable so should not be stored in the redux store
// and the redux services are not reachable from the components
export const audioContext = new AudioContext({
  latencyHint: "playback",
  sampleRate: 44100, // All our files are 44.1kHz
})
export const masterGainNode = audioContext.createGain()
export const analyser = audioContext.createAnalyser()
masterGainNode.connect(analyser)

// This function is used to create an audio player using the Web Audio API instead of the Howler library
export const createAudioPlayer = (dispatch: (action: any) => any) => {
  let isPlaying = false
  let volume = 1
  let playingSource:
    | {
        gainNode: GainNode
        source: AudioBufferSourceNode
        startTime: number
      }
    | undefined
  let stoppingPlayingSource:
    | {
        gainNode: GainNode
        source: AudioBufferSourceNode
        startTime: number
      }
    | undefined

  masterGainNode.connect(audioContext.destination)

  /**
   * This is a workaround to avoid a memory leak in the Web Audio API.
   * This code run before each hot reload to close the audio context during development.
   */
  if (import.meta.hot) {
    import.meta.hot.on("vite:beforeUpdate", () => {
      // if already playing a track then stop it before reloading the code
      if (playingSource && playingSource.source) {
        masterGainNode?.disconnect(analyser)
        playingSource.source.stop()
        try {
          playingSource.source.disconnect()
          playingSource.gainNode?.disconnect()
        } catch (e) {
          // can throw if the audio context is closed on hot page reload
        }
        audioContext.close()
      }
    })
  }

  let playNextTimeout: NodeJS.Timeout

  // There is no easy way to detect if autoplay is allowed by the browser except by trying to play a track
  const isAutoPlayAllowed = false

  // This array defined the curve used to fade in and fade out the tracks
  // It has been generated with the following code (base on the Music Player Daemon's fade curve):
  // var values = []; for (x = 0; x <= 1; x+=0.1) { values.push(Math.pow(Math.sin(Math.PI * x / 2), 2)) }; console.log(values)
  const fadeCurveValues = [
    0, 0.024471741852423214, 0.09549150281252627, 0.20610737385376346,
    0.3454915028125263, 0.4999999999999999, 0.6545084971874737,
    0.7938926261462365, 0.9045084971874736, 0.9755282581475766, 1,
  ]

  // Schedule the next track to play after the current track ends
  const scheduleEndOfTrack = () => {
    clearTimeout(playNextTimeout)
    if (isPlaying && playingSource?.source?.buffer?.duration) {
      // We schedule the next track to play after the current track ends
      // We subtract crossfadeDuration to avoid a gap between tracks
      const msToPlayNext = Math.max(
        0,
        playingSource.source.buffer.duration * 1000 - crossfadeDuration,
      )
      playNextTimeout = setTimeout(() => {
        log.debug(
          "[AudioPlayer][scheduleEndOfTrack] Play the next track before the end of the current track",
        )
        dispatch(
          playerActions.playNextTrack("audioPlayerOnScheduledEndOfTrack"),
        )
      }, msToPlayNext)
    }
  }

  const loadTrack = async (audioBuffer: AudioBuffer) => {
    log.debug("[AudioPlayer][loadTrack] Playing the new track")
    if (audioContext) {
      // if already playing a track then stop it
      if (isPlaying && playingSource) {
        stop()
      }
    }

    const gainNode = audioContext.createGain()

    // Start the fade in
    gainNode.gain.cancelScheduledValues(audioContext.currentTime)
    gainNode.gain.setValueCurveAtTime(
      fadeCurveValues,
      audioContext.currentTime,
      crossfadeDuration / 1000, // seconds
    )

    const source = audioContext.createBufferSource()
    source.buffer = audioBuffer
    source.connect(gainNode).connect(masterGainNode)

    source.onended = () => {
      log.debug(
        "[AudioPlayer][loadTrack][onended] the track reached the end or stopped",
      )
      const isStillPlayingSource = source === playingSource?.source
      if (isStillPlayingSource && isPlaying) {
        log.debug(
          "[AudioPlayer][loadTrack][onended] the track is still the playing track so the track ended before the start of the crossfade",
        )
        // Then clear the state
        stop(true)
        // And play the next track
        dispatch(playerActions.playNextTrack("audioPlayerOnEnded"))
      }
    }

    source.start()

    playingSource = { startTime: audioContext.currentTime, source, gainNode }

    isPlaying = true

    scheduleEndOfTrack()

    notifyPlayerStatus()

    if (!isAutoPlayAllowed) {
      // check if the track is playing after a short delay
      setTimeout(() => {
        if (source.context.state !== "running") {
          log.debug("[AudioPlayer][loadTrack] Autoplay is not allowed")
          stop(true)
        }
      }, 1000)
    }
  }

  const stop = (immediately = false) => {
    log.debug("[AudioPlayer][stop] Stop the playing track", {
      immediately,
      isPlaying,
    })
    if (playNextTimeout) {
      // if an end of track is scheduled then cancel it
      clearTimeout(playNextTimeout)
    }
    if (isPlaying && playingSource) {
      // if  a track is already stopping
      if (stoppingPlayingSource) {
        log.debug(
          "[AudioPlayer][stop] A track is already stopping then stop it immediately",
        )
        // then stop the previous stopping track before the end of the crossfade
        stoppingPlayingSource.source.stop()
        stoppingPlayingSource.source.disconnect()
        stoppingPlayingSource.gainNode.disconnect()
      }

      stoppingPlayingSource = playingSource

      // if we want to stop immediately then stop the track without crossfade
      if (immediately) {
        log.debug("[AudioPlayer][stop] Stop the track immediately")
        stoppingPlayingSource.source.stop()
        stoppingPlayingSource.source.disconnect()
        stoppingPlayingSource.gainNode.disconnect()
        stoppingPlayingSource = undefined
        isPlaying = false
        notifyPlayerStatus()
        return
      } else {
        log.debug("[AudioPlayer][stop] Start the fade out")

        // Start the fade out
        stoppingPlayingSource.gainNode.gain.cancelScheduledValues(
          audioContext.currentTime,
        )
        stoppingPlayingSource.gainNode.gain.setValueCurveAtTime(
          fadeCurveValues.toReversed(),
          audioContext.currentTime,
          crossfadeDuration / 1000, // seconds
        )
        setTimeout(() => {
          log.debug("[AudioPlayer][stop] Stop the track after the fade out")
          stoppingPlayingSource?.source.stop()
          stoppingPlayingSource?.source.disconnect()
          stoppingPlayingSource?.gainNode.disconnect()
          // if the stopping source is still the playing source then reset the playing source
          if (stoppingPlayingSource === playingSource) {
            log.debug("[AudioPlayer][stop] Reset the playing source")
            // reset the playing source to allow the garbage collector to free the memory
            playingSource = undefined
          }
          stoppingPlayingSource = undefined
        }, crossfadeDuration)
      }
    }
    isPlaying = false
    notifyPlayerStatus()
  }

  const _throttleSetGain = throttle((volume: number) => {
    masterGainNode?.gain.setTargetAtTime(volume, audioContext.currentTime, 0.01)
  }, 100)

  const setVolume = (newVolume: number) => {
    volume = newVolume
    _throttleSetGain(volume)
    notifyPlayerStatus()
  }

  const getVolume = () => volume

  const setTrackUrl = async (audioBuffer: AudioBuffer) => {
    if (playingSource?.source) {
      stop()
    }
    await loadTrack(audioBuffer)
  }

  const notifyPlayerStatus = () => {
    dispatch(
      playerActions.setPlayerStatus({
        isPlaying: !!isPlaying,
        volume,
      }),
    )
  }

  const getPosition = () => {
    if (isPlaying && playingSource?.source) {
      return audioContext.currentTime - playingSource.startTime
    }
    return 0
  }

  const getDuration = () => {
    if (playingSource?.source?.buffer) {
      return playingSource.source.buffer.duration
    }
    return 0
  }

  const getProgress = () => {
    if (playingSource?.source) {
      return getPosition() / getDuration()
    }
    return 0
  }

  return {
    setTrackUrl,
    stop,
    setVolume,
    getVolume,
    getPosition,
    getDuration,
    getProgress,
    getAudioContext: () => audioContext,
  }
}

export type AudioPlayer = ReturnType<typeof createAudioPlayer>
