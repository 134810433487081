import { useTheme } from "@emotion/react"
import { useTranslation } from "react-i18next"
import { Text } from "src/components/lib/Text"
import { getCurrentPlaylist } from "src/state/redux/player.selectors"
import { useAppSelector } from "../../state/redux/hooks"

export function PlaylistItem({
  playlist,
  onClick,
}: {
  playlist: Spectre.Playlist
  onClick: () => void
}) {
  const theme = useTheme()
  const currentPlaylist = useAppSelector(getCurrentPlaylist)
  const { t } = useTranslation()

  return (
    <button
      css={{
        padding: ".5rem",
        display: "grid",
        gap: ".25rem",
        borderRadius: theme.radius.md,
        boxShadow: theme.shadows.md,
        ...(playlist.id === currentPlaylist?.id
          ? {
              background: theme.colors.accent,
              color: theme.colors.white,
            }
          : {
              background: theme.colors.bg.primary,
              color: theme.colors.text.secondary,
              "&:hover": { color: theme.colors.text.primary },
            }),
      }}
      onClick={onClick}
    >
      <img
        src={playlist.image}
        alt=""
        css={{
          background: theme.colors.bg.primary,
          width: "100%",
          height: 100,
          borderRadius: theme.radius.md,
          objectFit: "cover",
        }}
      />
      <Text font="karelia" weight="medium">
        {playlist.name}
      </Text>
      <Text size="sm">
        {t("{{count}} tracks", { count: playlist.tracks.length })}
      </Text>
    </button>
  )
}
