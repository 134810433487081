import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppThunk } from "./store"

export type PageId =
  | "firstLoad"
  | "player"
  | "loading"
  | "firstSyncError"
  | "otherDeviceConnected"
  | "browserNotSupported"
  | "killSwitch"

export interface RouterState {
  currentPageId: PageId
  queryParams: {
    token?: string
    id?: string // uuid
    activate: boolean
  }
}

const initialState: RouterState = {
  currentPageId: "player",
  queryParams: {
    activate: false,
  },
}

export const routerSlice = createSlice({
  name: "router",
  initialState,
  reducers: {
    navigateTo: (state, { payload }: PayloadAction<PageId>) => {
      window.scrollTo(0, 0) // this is a hack
      state.currentPageId = payload
    },
    setQueryParams: (
      state,
      {
        payload,
      }: PayloadAction<{ token?: string; id?: string; activate: boolean }>,
    ) => {
      state.queryParams = payload
    },
  },
})

const extractTheQueryParams = (): AppThunk => async (dispatch) => {
  // Iterate over the query params and dispatch the appropriate actions to store them
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get("token") || undefined
  const id = urlParams.get("id") || undefined
  const activate = urlParams.get("activate") === "true"
  dispatch(routerSlice.actions.setQueryParams({ token, id, activate }))
}

export const routerActions = {
  extractTheQueryParams,
  ...routerSlice.actions,
}
