import type { Logger } from "@spectre-music/shared/log.js"

const log: Logger = {
  info: (...data: any) => {
    console.info(...data)
  },
  debug: (...data: any) => {
    // eslint-disable-next-line no-console
    console.debug(...data)
  },
  warn: (...data: any) => {
    console.warn(...data)
  },
  verbose: (...data: any) => {
    // eslint-disable-next-line no-console
    console.debug(...data)
  },
  error: (...data: any) => {
    console.error(...data)
  },
}

export default log
