import { registerSW } from "virtual:pwa-register"
import * as Sentry from "@sentry/react"
import log from "./log"

export type serviceWorkerType = {
  updateSW: (reloadPage?: boolean | undefined) => Promise<void>
  onNewVersionAvailable: (cb: () => void) => void
}

let newVersionAvailableCallback: (() => void) | undefined

export function registerServiceWorker() {
  // Each hour, check if there is a new version of the app
  // Except in dev mode where we want to check every 2 seconds
  const intervalMS = import.meta.env.DEV ? 2000 : 60 * 60 * 1000
  let refreshTimeoutInstance: NodeJS.Timeout | undefined

  const isControlled = !!navigator.serviceWorker.controller

  const updateSW = registerSW({
    onRegisteredSW(swUrl, r) {
      try {
        log.info(
          "[RegisterServiceWorker][onRegisteredSW] Service worker has been registered.",
          swUrl,
        )
        if (!r) {
          throw new Error("Impossible to register the service worker")
        }
        // Fetch the new app (is needed) at load time in the background
        r && r.update()
        log.info(
          "[RegisterServiceWorker][onRegisteredSW] Service worker is installing...",
          r.installing,
        )
        // Every hour, check is there is a new version of the app
        r &&
          setInterval(async () => {
            if (!(!r.installing && navigator)) return

            if ("connection" in navigator && !navigator.onLine) return

            // This fetch should avoid issue with some edge cases like:
            // - server is down when calling the update method
            // - the user can go offline at any time
            // See https://vite-pwa-org.netlify.app/guide/periodic-sw-updates.html#handling-edge-cases
            const resp = await fetch(swUrl, {
              cache: "no-store",
              headers: {
                "Cache-Control": "no-cache",
              },
            })

            if (resp?.status === 200) {
              log.info(
                "[RegisterServiceWorker][onRegisteredSW] Checking for new version of the app...",
              )
              await r.update()
            }
          }, intervalMS)
      } catch (error) {
        log.error(
          "[RegisterServiceWorker][onRegisteredSW] Impossible to register the service worker",
          error,
        )
        Sentry.captureException(error)
      }
    },
    onNeedRefresh() {
      log.info("[RegisterServiceWorker][onNeedRefresh] New content available.")
      // Check if the app is already waiting for a refresh
      if (!refreshTimeoutInstance) {
        if (newVersionAvailableCallback) {
          // call the callback to display a notification
          newVersionAvailableCallback()
        }
        // Schedule the page reload at 3 am the next day
        // Except in dev mode where we want to reload after 10 seconds
        const now = new Date()
        const nextDayAt3am = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() + 1, // next day
          3, // 3 am
        )
        const diff = import.meta.env.DEV
          ? 5000
          : nextDayAt3am.getTime() - now.getTime()
        log.info(
          `[RegisterServiceWorker][onNeedRefresh] The new service worker will be load in ${
            diff / 1000
          }s`,
        )
        refreshTimeoutInstance = setTimeout(async () => {
          refreshTimeoutInstance = undefined
          log.info(
            "[RegisterServiceWorker][onNeedRefresh] Loading the new service worker...",
          )
          await updateSW()
          log.info(
            "[RegisterServiceWorker][onNeedRefresh] The new service worker has been loaded.",
          )
          // This is workaround for the issue where the app is uncontrolled at load time
          // See https://github.com/vite-pwa/vite-plugin-pwa/issues/601
          if (!isControlled) {
            log.info(
              "[RegisterServiceWorker][onNeedRefresh] The app was uncontrolled at load time, so force the reload of the page...",
            )
            setTimeout(() => {
              window.location.reload()
            }, 2000)
          }
        }, diff)
      } else {
        log.info(
          "[RegisterServiceWorker][onNeedRefresh] The page is already scheduled for a reload.",
        )
      }
    },
    onOfflineReady() {
      log.info(
        "[RegisterServiceWorker][onOfflineReady] Content is cached for offline use.",
      )
    },
    onRegisterError(error) {
      log.error(
        "[RegisterServiceWorker][onRegisterError] Impossible register the service worker",
        error.message,
      )
      Sentry.captureException(error)
    },
  })

  return {
    updateSW,
    onNewVersionAvailable: (cb: () => void) => {
      newVersionAvailableCallback = cb
    },
  }
}
