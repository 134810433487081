import {
  getTheNextScheduledPlaylistTime,
  nextTrackToPlayForProgram,
  nextTrackToPlayForPlaylist,
} from "@spectre-music/shared/scheduler.js"
import log from "../../log"
import {
  getBaseUrl,
  getPlaylistById,
  getProgram,
} from "../redux/manifest.selectors"
import { getTimezoneOffset } from "../redux/device.selectors"
import { RootState } from "../redux/store"
import type { TracksDB } from "./tracksDB"

function nextScheduledPlaylistTime(state: RootState) {
  const { uuid } = state.device
  if (typeof state.manifest !== "object") {
    throw new Error("[nextScheduledPlaylistTime] manifest is not loaded")
  }
  const zoneManifest = getProgram(state)
  if (!zoneManifest) {
    throw new Error(
      "[getNextTrack] no zone found in the state. The manifest is probably not loaded or corrupted",
    )
  }

  const deviceTimezoneOffset = getTimezoneOffset()

  log.info(
    `[ZoneController][nextScheduledPlaylistTime] Calculating next scheduled playlist time`,
  )
  const date = getTheNextScheduledPlaylistTime({
    deviceUuid: uuid,
    programPlaylists: zoneManifest.musicProgram.playlists.map((p) => {
      return {
        _id: p.id,
        playlistId: p.playlistId,
        scheduleV3: p.schedule,
      }
    }),
    timeZoneUTCOffset: deviceTimezoneOffset,
    log,
  })
  log.info(
    `[ZoneController][nextScheduledPlaylistTime] Next scheduled playlist time: ${date}`,
  )
  return date
}
async function getNextTrack(state: RootState, tracksDB: TracksDB) {
  const { uuid } = state.device
  const { manualPlaylistId, listeningHistory, blacklist } = state.player
  if (typeof state.manifest.data !== "object") {
    throw new Error("[getNextTrack] manifest is not loaded")
  }
  const zoneManifest = getProgram(state)
  if (!zoneManifest) {
    throw new Error(
      "[getNextTrack] no zone found in the state. The manifest is probably not loaded or corrupted",
    )
  }

  // Get the base URL from the manifest
  // Then use it to build the list of all stored tracks ids
  const baseUrl = getBaseUrl(state)
  if (typeof baseUrl !== "string") {
    throw new Error(
      "[getNextTrack] no baseUrl found in the state. The manifest is probably not loaded or corrupted",
    )
  }
  const allStoredTracksIds = (await tracksDB.getAllUrls()).map((url) =>
    url.replace(baseUrl, ""),
  )

  let track:
    | Awaited<ReturnType<typeof nextTrackToPlayForProgram>>
    | Awaited<ReturnType<typeof nextTrackToPlayForPlaylist>>

  if (typeof manualPlaylistId === "string") {
    // manual
    const playlist = getPlaylistById(state, manualPlaylistId)
    track = await nextTrackToPlayForPlaylist({
      deviceUuid: uuid,
      playlist: {
        _id: playlist.playlistId,
        t: playlist.tracks.filter((trackId) =>
          // Keep only the tracks that are stored locally
          allStoredTracksIds.includes(trackId),
        ),
      },
      programPlaylist: {
        _id: playlist.id,
        playlistId: playlist.playlistId,
        scheduleV3: playlist.schedule,
      },
      history: listeningHistory,
      blacklistedTracks: blacklist,
      log,
    })
  } else {
    // automatic schedule
    const deviceTimezoneOffset = getTimezoneOffset()
    track = await nextTrackToPlayForProgram({
      deviceUuid: uuid,
      programId: zoneManifest.musicProgram.id,
      programPlaylists: zoneManifest.musicProgram.playlists.map((p) => {
        return {
          _id: p.id,
          playlistId: p.playlistId,
          scheduleV3: p.schedule,
        }
      }),
      findPlaylists: async (ids: string[]) =>
        zoneManifest.musicProgram.playlists
          .filter((p) => {
            return ids.includes(p.playlistId)
          })
          .map((p) => {
            return {
              _id: p.playlistId,
              t: p.tracks.filter((trackId) =>
                // Keep only the tracks that are stored locally
                allStoredTracksIds.includes(trackId),
              ),
            }
          }),
      timeZoneUTCOffset: deviceTimezoneOffset,
      history: listeningHistory,
      blacklistedTracks: blacklist,
      log,
    })
  }

  const isSilence = track.trackId === "silence"

  if (typeof track.trackId !== "string") {
    throw new Error("[getNextTrack] no track returned by the scheduler")
  } else if (!isSilence && typeof track.programPlaylistId !== "string") {
    throw new Error(
      "[getNextTrack] no programPlaylistId returned with the track by the scheduler",
    )
  }

  return {
    programPlaylistId: track.programPlaylistId,
    trackId: track.trackId,
    isManualPlaylist: !!manualPlaylistId,
    programId: zoneManifest.musicProgram.id,
    zoneId: zoneManifest.zone.id,
  }
}

function getNextForcedTrack(state: RootState) {
  const { manualPlaylistId, forcedNextTrack } = state.player
  if (typeof state.manifest.data !== "object") {
    throw new Error("[getNextTrack] manifest is not loaded")
  }
  const zoneManifest = getProgram(state)
  if (!zoneManifest) {
    throw new Error(
      "[getNextTrack] no zone found in the state. The manifest is probably not loaded or corrupted",
    )
  }
  if (!forcedNextTrack) {
    throw new Error(
      "[getNextTrack] no forced next track found in the state. This method should not be called if there is no forced next track",
    )
  }

  return {
    programPlaylistId: forcedNextTrack.programPlaylistId,
    trackId: forcedNextTrack.trackId,
    isManualPlaylist: !!manualPlaylistId,
    programId: zoneManifest.musicProgram.id,
    zoneId: zoneManifest.zone.id,
  }
}

export const scheduler = {
  getNextTrack,
  nextScheduledPlaylistTime,
  getNextForcedTrack,
}
export type Scheduler = typeof scheduler
