import styled from "@emotion/styled"

export const MenuButton = styled.button(({ theme }) => ({
  borderBottom: theme.border.subtle,
  "&:last-child": {
    borderBottom: "none",
  },
  padding: ".5rem 1rem",
  width: "100%",
  display: "flex",
  gap: ".75rem",
  alignItems: "center",
  minHeight: "3rem",
  "&:hover": {
    background: theme.colors.bg.hover,
    color: theme.colors.text.hover,
  },
  fontWeight: theme.font.weight.medium,
  ...theme.text.sm,
}))
