import { useTheme } from "@emotion/react"
import { useState } from "react"
import { useAppDispatch, useAppSelector } from "src/state/redux/hooks"
import {
  getCurrentTrack,
  getIsMuted,
  getIsPlayingTrack,
} from "src/state/redux/player.selectors"
import { playerActions } from "src/state/redux/player.slice"
import { USER } from "src/utils/constants"
import { useTranslation } from "react-i18next"
import {
  Play,
  SkipForward,
  Square,
  Trash,
  Volume2,
  VolumeX,
  X,
} from "react-feather"
import { usePlayer } from "src/hooks/useThunkExtraArgument"
import { VolumeSlider } from "../VolumeSlider"
import { PlayerProgress } from "../TrackProgress"

import { Group } from "../lib/Group"
import { Text } from "../lib/Text"
import { Grid } from "../lib/Grid"
import { Box } from "../lib/Box"
import { PageSection } from "../lib/PageSection"
import { Button } from "../lib/Button"
import WaveForm from "../Waveform/Waveform"
import { Modal } from "../Modal"
import { MenuButton } from "../MenuButton"
import { MenuTitle } from "../MenuTitle"
import { PlayingIcon } from "./PlayingIcon"

export function Player() {
  const [isSkipConfirmVisible, setIsSkipConfirmVisible] = useState(false)
  const track = useAppSelector(getCurrentTrack)
  const isPlaying = useAppSelector(getIsPlayingTrack)
  const isMuted = useAppSelector(getIsMuted)

  const nextScheduledPlaylistTime = useAppSelector(
    (state) => state.player.nextScheduledPlaylistTime,
  )
  const { t } = useTranslation()
  const player = usePlayer()

  const theme = useTheme()

  const dispatch = useAppDispatch()
  return (
    <PageSection
      py="3rem"
      css={{
        background: theme.colors.bg.secondary,
        borderBottom: theme.border.subtle,
      }}
    >
      <Grid gap="2rem">
        <Group>
          <Text weight="bold" color="accent" size="sm">
            {t("NOW PLAYING")}
          </Text>
          <PlayingIcon />
        </Group>
        <Grid gap="4rem" columns="auto 1fr" align="center">
          <Box
            p="2rem"
            css={{
              background: theme.colors.bg.primary,
              borderRadius: theme.radius.lg,
              boxShadow: theme.shadows.lg,
            }}
          >
            <img
              src={track?.pictureUrl}
              alt=""
              css={{
                background: theme.colors.bg.secondary,
                width: 220,
                height: 220,
                borderRadius: theme.radius.md,
                objectFit: "cover",
              }}
            />
          </Box>
          <Box>
            <Grid>
              <Text
                size="lg"
                opacity={0.6}
                weight="medium"
                css={{ textTransform: "uppercase" }}
              >
                {track?.artist ?? "/////"}
              </Text>
              <Text font="karelia" weight="bold" size="xxl">
                {track?.title ?? "//////////////"}
              </Text>
            </Grid>
          </Box>
        </Grid>
        <Group
          p="2rem"
          mt="1rem"
          gap="3rem"
          css={{
            background: theme.colors.bg.primary,
            borderRadius: theme.radius.lg,
            boxShadow: theme.shadows.lg,
          }}
        >
          <Group>
            <Button
              onClick={() =>
                isPlaying
                  ? dispatch(playerActions.pause(USER.PHYSICAL))
                  : dispatch(playerActions.playNextTrack(USER.PHYSICAL))
              }
              aria-label={isPlaying ? t("Stop") : t("Play")}
              size="xl"
              ball
            >
              {isPlaying ? (
                <Square color={theme.colors.accent} size={36} />
              ) : (
                <Play
                  color={theme.colors.accent}
                  size={36}
                  css={{ position: "relative", left: 3 }}
                />
              )}
            </Button>
            <Button
              onClick={() => setIsSkipConfirmVisible(true)}
              aria-label={t("Skip Track")}
              size="lg"
              ball
            >
              <SkipForward />
            </Button>
          </Group>
          <Group>
            <Button
              aria-label={isMuted ? t("Unmute") : t("Mute")}
              size="lg"
              onClick={() =>
                dispatch(playerActions.setMuted(!isMuted, USER.PHYSICAL))
              }
              ball
            >
              {isMuted ? <VolumeX /> : <Volume2 />}
            </Button>
            <VolumeSlider css={{ width: "10rem" }} />
          </Group>
          <Group css={{ flexGrow: 1 }}>
            <PlayerProgress color="accent" weight="bold" size="sm" />
            <WaveForm
              data={track?.waveform || []}
              css={{ width: 0, flexGrow: 1 }}
              isPlaying={isPlaying}
              getProgress={() => player.getProgress()}
            />
            <PlayerProgress remaining color="secondary" size="sm" />
          </Group>
        </Group>
        <Modal
          isOpen={isSkipConfirmVisible}
          onRequestClose={() => setIsSkipConfirmVisible(false)}
        >
          <MenuTitle>
            {t("Skip `{{title}}`?", { title: track?.title })}
          </MenuTitle>
          <MenuButton
            onClick={() => {
              setIsSkipConfirmVisible(false)
              dispatch(playerActions.playNextTrack(USER.PHYSICAL))
            }}
          >
            <SkipForward />
            {t("Skip")}
          </MenuButton>
          <MenuButton
            onClick={() => {
              setIsSkipConfirmVisible(false)
              dispatch(playerActions.blacklistCurrentTrack(USER.PHYSICAL))
            }}
          >
            <Trash />
            {t("Skip and Blacklist")}
          </MenuButton>
          <MenuButton onClick={() => setIsSkipConfirmVisible(false)}>
            <X />
            {t("Cancel")}
          </MenuButton>
        </Modal>

        {nextScheduledPlaylistTime && (
          <p>
            Resuming the schedule at{" "}
            {nextScheduledPlaylistTime &&
              new Date(nextScheduledPlaylistTime)?.toISOString()}
          </p>
        )}
      </Grid>
    </PageSection>
  )
}
