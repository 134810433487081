import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import throttle from "lodash/throttle"

import type { SocketManager } from "src/socket"
import log from "../../log"
import { getErrorMessage, nextDateWithMinutes } from "../../utils/utils"
import { crossfadeDuration, PlayerStatus } from "../services/audioPlayer"
import { storage } from "../services/storage"
import type { AppThunk } from "./store"
import { getLoadedTrackByUrl, Track, tracksActions } from "./tracks.slice"
import { getCurrentTrack } from "./player.selectors"
import { getProgram, getTrackUrl } from "./manifest.selectors"
import { killSwitchActions } from "./killswitch.slice"
import { appSelectors } from "./app.selectors"

export type ListeningHistory = { trackId: string; programPlaylistId: string }[]

type LoadedTrack = Omit<Track, "audioBuffer" | "format">

export interface PlayerState {
  programPlaylistId?: string
  trackId?: string
  manualPlaylistId?: string
  backToAutoTime?: number // timestamp
  nextScheduledPlaylistTime?: number // timestamp
  loadedTrack?: LoadedTrack
  playerStatus: PlayerStatus
  // We keep the listening history in the state for consistency with the redux pattern
  // It should not bring any performance issue since it will be copied only when it will be modified
  listeningHistory: ListeningHistory
  blacklist: string[]
  forcedNextTrack?: { trackId: string; programPlaylistId: string }
  isResumeScheduleConfirmOpen: boolean
  isPlaylistSelectOpen: boolean
}

const initialState: PlayerState = {
  listeningHistory: [],
  blacklist: [],
  playerStatus: {
    isPlaying: false,
    volume: 1,
  },
  isResumeScheduleConfirmOpen: false,
  isPlaylistSelectOpen: false,
}

export const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    setCurrentTrack: (
      state,
      {
        payload,
      }: PayloadAction<{
        programPlaylistId?: string
        trackId: string
      }>,
    ) => {
      state.programPlaylistId = payload.programPlaylistId
      state.trackId = payload.trackId
      // Store in the history the tracks from the most recently played
      // This is very important for the scheduler to work properly
      if (payload.trackId !== "silence") {
        if (!payload.programPlaylistId) {
          throw new Error(
            `[Player][setCurrentTrack] No programPlaylistId provided for the track ${payload.trackId}`,
          )
        }
        state.listeningHistory.unshift({
          trackId: payload.trackId,
          programPlaylistId: payload.programPlaylistId,
        })
        // Purge old history if too long
        if (state.listeningHistory.length >= 10000) {
          state.listeningHistory.splice(-1, 1)
        }
      }
    },
    setListeningHistory: (
      state,
      { payload }: PayloadAction<ListeningHistory>,
    ) => {
      state.listeningHistory = payload
    },
    setLoadedTrack: (
      state,
      { payload }: PayloadAction<LoadedTrack | undefined>,
    ) => {
      state.loadedTrack = payload
    },
    setManualPlaylistId: (
      state,
      { payload }: PayloadAction<string | undefined>,
    ) => {
      state.isPlaylistSelectOpen = false
      state.isResumeScheduleConfirmOpen = false
      state.manualPlaylistId = payload
      if (!payload) {
        state.backToAutoTime = undefined
      }
    },
    setBackToAutoTime: (
      state,
      { payload }: PayloadAction<number | undefined>,
    ) => {
      state.backToAutoTime = payload
    },
    setNextScheduledPlaylistTime: (
      state,
      { payload }: PayloadAction<number | undefined>,
    ) => {
      state.nextScheduledPlaylistTime = payload
    },
    setPlayerStatus: (state, { payload }: PayloadAction<PlayerStatus>) => {
      state.playerStatus = payload
    },
    blacklistTrack: (
      state,
      { payload }: PayloadAction<{ trackId: string }>,
    ) => {
      state.blacklist.push(payload.trackId)
    },
    setBlacklistedTrack: (state, { payload }: PayloadAction<string[]>) => {
      state.blacklist = payload
    },
    setForcedNextTrack: (
      state,
      {
        payload,
      }: PayloadAction<{
        trackId: string
        programPlaylistId: string
      }>,
    ) => {
      state.forcedNextTrack = payload
    },
    resetForcedNextTrack: (state) => {
      state.forcedNextTrack = undefined
    },
    setIsResumeScheduleConfirmOpen: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isResumeScheduleConfirmOpen = payload
    },
    _setIsPlaylistSelectOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPlaylistSelectOpen = payload
    },
  },
})

const setBlacklistAndPersist =
  (blacklist: string[]): AppThunk =>
  async (dispatch, getState, { tracksDB }) => {
    dispatch(playerActions.setBlacklistedTrack(blacklist))
    storage.blacklistedTracks.set(blacklist)
  }

const blacklistCurrentTrack =
  (username: string): AppThunk =>
  async (dispatch, getState, { socket, tracksDB }) => {
    const { trackId } = getState().player
    if (trackId === "silence") {
      throw new Error(
        "[Player][blacklistCurrentTrack] Cannot blacklist the silence track",
      )
    }
    if (trackId) {
      dispatch(playerActions.blacklistTrack({ trackId }))
      storage.blacklistedTracks.set(getState().player.blacklist)
      socket.notifyBlacklistTrack(trackId, username)
    }
    await dispatch(playNextTrack(username))
  }

const playNextTrack =
  (username: string): AppThunk =>
  async (dispatch, getState, { scheduler, socket, tracksDB }) => {
    log.debug("[Player][playNextTrack] Playing next track", { username })

    // check if the kill switch should be activated
    dispatch(killSwitchActions.enablingKillSwitchIfTTLExpired())

    if (getState().killSwitch.killSwitchActivated) {
      return // do not play anything if the kill switch is activated
    }

    // Reset the manual playlist if is time to go back to the automatic playlist
    const { manualPlaylistId, backToAutoTime } = getState().player
    if (manualPlaylistId) {
      const currentTime = new Date()
      if (backToAutoTime && currentTime > new Date(backToAutoTime)) {
        // Back to auto schedule
        log.info("[Player][playNextTrack] Back to auto schedule")
        dispatch(playerActions.setManualPlaylistId(undefined))
      }
    }

    const wasPreviouslyPlayingASilence = getState().player.trackId === "silence"

    // Check if there is a forced track waiting to be played
    const forcedNextTrack = getState().player.forcedNextTrack
    let next: Awaited<ReturnType<typeof scheduler.getNextTrack>>
    if (forcedNextTrack) {
      // Then set forced track to be played
      log.info(
        `[Player][playNextTrack] Play the forced track ${forcedNextTrack.trackId}`,
      )
      next = scheduler.getNextForcedTrack(getState())
      dispatch(playerActions.resetForcedNextTrack())
    } else {
      // Otherwise randomize the next track to play based on the schedule
      next = await scheduler.getNextTrack(getState(), tracksDB)
    }
    // Load and play the track
    dispatch(playerActions.setCurrentTrack(next))
    // Update the listeningHistory in the local DB
    storage.listeningHistory.set(getState().player.listeningHistory)
    try {
      await dispatch(loadCurrentTrack())
    } catch (e) {
      log.error("[Player][playNextTrack] Error while loading the track", e)
      if (getErrorMessage(e).includes("No track found")) {
        // If the track is no found then play the next one
        await dispatch(playNextTrack("track not found"))
      }
    }

    if (!wasPreviouslyPlayingASilence && next.trackId === "silence") {
      // If we are going to play a silence and the previous track was not a silence, send a ran out event
      log.info("[Player][playNextTrack] Sending ran out event")
      socket.notifyRanOut(next.zoneId, next.programId)
    } else if (next.trackId !== "silence") {
      // If we are going to play a track that is not a silence, send a play event
      log.info("[Player][playNextTrack] Sending play event", { track: next })
      socket.notifyPlayTrack(
        next.trackId,
        next.zoneId,
        next.isManualPlaylist,
        username,
        next.programPlaylistId,
        next.programId,
      )
    }
    if (next.trackId === "silence") {
      log.info("[Player][playNextTrack] Playing silence")
      // If we are going to play a silence then we need to set the nextScheduledPlaylistTime
      const nextScheduledPlaylistTime = scheduler
        .nextScheduledPlaylistTime(getState())
        ?.getTime()
      // Set the nextScheduledPlaylistTime to the next scheduled playlist time
      // This will allow to display the remaining time in the UI
      dispatch(
        playerSlice.actions.setNextScheduledPlaylistTime(
          nextScheduledPlaylistTime || undefined,
        ),
      )
    }
  }

const initPlayer =
  (): AppThunk =>
  async (dispatch, getState, { tracksDB }) => {
    await dispatch(tracksActions.initLoadedCount())

    // Load persisted blacklisted tracks
    dispatch(playerActions.setBlacklistedTrack(storage.blacklistedTracks.get()))
    const storedVolume = storage.volume.get()
    if (storedVolume !== undefined) {
      dispatch(_setVolume(storage.muted.get() ? 0 : storedVolume))
    }

    // if the app is ready, start playing
    if (appSelectors.isReady(getState())) {
      dispatch(playNextTrack("init"))
    } else {
      log.info(
        "[Player][initPlayer] App is not ready yet to start to play. Probably the tracks are not fully loaded",
        {
          tracksLoaded: getState().tracks.loadedCount,
        },
      )
    }
  }

const loadCurrentTrack =
  (): AppThunk => async (dispatch, getState, thunkApi) => {
    const { player } = thunkApi
    const state = getState()
    const trackId = state.player.trackId
    const trackUrl = trackId && getTrackUrl(state, trackId)
    const newTrack = trackUrl
      ? await getLoadedTrackByUrl(trackUrl, thunkApi)
      : undefined
    const previousTrack = getCurrentTrack(getState())
    if (previousTrack) {
      // Once we are done with the track, release the blob so it can be GC'd
      // We do this after a delay to make sure the player gets time to fade-out properly
      setTimeout(() => {
        if (previousTrack.pictureUrl) {
          URL.revokeObjectURL(previousTrack.pictureUrl)
        }
      }, crossfadeDuration)
    }
    dispatch(
      playerSlice.actions.setLoadedTrack({
        title: newTrack?.title,
        artist: newTrack?.artist,
        album: newTrack?.album,
        pictureUrl: newTrack?.pictureUrl,
        waveform: newTrack?.waveform || [],
      }),
    )
    if (newTrack) {
      player.setTrackUrl(newTrack.audioBuffer)
    } else {
      player.stop()
    }
  }

const pause =
  (username: string): AppThunk =>
  async (dispatch, getState, { player, socket }) => {
    // State is automatically updated by the audio player
    player.stop()
    if (!getState().auth.otherDeviceConnected) {
      const zoneId = getProgram(getState())?.zone.id
      await socket.notifyPausePlayer(username, "user", zoneId)
    }
  }

// Internal function. Can be called by the UI or by the socket
const _setVolume =
  (volume: number): AppThunk =>
  (dispatch, getState, { player }) => {
    // State is automatically updated by the audio player
    player.setVolume(volume)
    if (volume === 0) {
      storage.muted.set(true)
    } else {
      storage.muted.set(false)
      storage.volume.set(volume)
    }
  }

// Called by the app to set the volume, will also send to the server
const setVolume =
  (volume: number, username: string): AppThunk =>
  (dispatch, getState, { player }) => {
    dispatch(_setVolume(volume))
    dispatch(sendVolumeToVauxhall(username))
  }

const setMuted =
  (muted: boolean, username: string): AppThunk =>
  (dispatch, getState, { player }) => {
    const volume = muted ? 0 : Math.max(0.25, storage.volume.get() ?? 1)
    dispatch(setVolume(volume, username))
  }

const setIsPlaylistSelectOpen =
  (isOpen: boolean): AppThunk =>
  async (dispatch) => {
    // We use setTimeout so that PlaylistDrawer useClickOutside does not trigger closing the drawer when clicking a button to open it
    setTimeout(() => {
      dispatch(playerSlice.actions._setIsPlaylistSelectOpen(isOpen))
    }, 0)
  }

// Thunk that set the manual playlist and set the backToAutoTime date
const setManualPlaylist =
  (
    playlistId: string | undefined,
    skipCurrentTrack: boolean,
    user: string,
  ): AppThunk =>
  async (dispatch, getState) => {
    dispatch(playerSlice.actions.setManualPlaylistId(playlistId))

    // Reset the auto schedule the next day at 3 am
    if (playlistId) {
      const scheduleReset = getProgram(getState())?.scheduleReset || 180
      const backToAutoTime = nextDateWithMinutes(scheduleReset).getTime()
      dispatch(playerSlice.actions.setBackToAutoTime(backToAutoTime))
    }

    if (skipCurrentTrack) {
      dispatch(playNextTrack(user))
    }
  }

const _throttleNotifyVolumeChange = throttle(
  (socket: SocketManager, volume: number, zoneId: string, username: string) => {
    socket.notifyVolumeChange(volume, zoneId, username)
  },
  1000,
)

const sendVolumeToVauxhall = (username: string): AppThunk => {
  return (dispatch, getState, { socket }) => {
    const volume = getState().player.playerStatus.volume
    const zoneId = getProgram(getState())?.zone.id
    if (!zoneId) {
      throw new Error(
        "[Player][setVolume] No zone found in the state. The manifest is probably not loaded or corrupted",
      )
    }
    _throttleNotifyVolumeChange(socket, volume, zoneId, username)
  }
}

const onReceiveZoneData =
  (zone: { id: string; volumes: { music: number } }): AppThunk =>
  (dispatch, getState, { player }) => {
    // The volume coming from the server is a number between 1 and 2
    // But the player expects a number between 0 and 1
    const volume = zone.volumes.music - 1
    log.info(`[Player][onReceiveZoneData] set the volume`, volume)
    dispatch(_setVolume(volume))
  }

const forceTrack =
  (trackId: string, username: string): AppThunk =>
  async (dispatch, getState) => {
    log.info("[Player][forceTrack] Forcing track", { trackId, username })
    const state = getState()
    const programPlaylistId = state.player.programPlaylistId
    if (!programPlaylistId) {
      throw new Error(
        "[Player][forceTrack] No programPlaylistId provided for the track",
      )
    }
    dispatch(
      playerSlice.actions.setForcedNextTrack({ trackId, programPlaylistId }),
    )
  }

const onReceiveCommand =
  (
    command: Commands.PlayerCommands,
    param: string,
    username: string,
  ): AppThunk =>
  async (dispatch) => {
    switch (command) {
      case "play":
        dispatch(playNextTrack(username))
        break
      case "skip":
        dispatch(playNextTrack(username))
        break
      case "blacklist":
        dispatch(blacklistCurrentTrack(username))
        break
      case "stop":
        dispatch(pause(username))
        break
      case "setVolume":
        log.warn(
          `[Player][onReceiveCommand] received the deprecated event setVolume`,
        )
        break
      case "selectPlaylist":
        dispatch(setManualPlaylist(param, false, username))
        break
      case "selectPlaylistSkip":
        dispatch(setManualPlaylist(param, true, username))
        break
      case "forceTrack":
        dispatch(playerActions.forceTrack(param, username))
        break
      case "reload":
        window.location.reload()
        break
      default:
        log.warn(
          `[Player][onReceiveCommand] Received unknown command ${command} from ${username}`,
        )
        break
    }
  }

export const playerActions = {
  onReceiveCommand,
  onReceiveZoneData,
  playNextTrack,
  blacklistCurrentTrack,
  pause,
  setManualPlaylist,
  forceTrack,
  setBlacklistAndPersist,
  initPlayer,
  setVolume,
  setMuted,
  sendVolumeToVauxhall,
  setIsPlaylistSelectOpen,
  ...playerSlice.actions,
}
