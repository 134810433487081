import { css, useTheme } from "@emotion/react"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { Box } from "src/components/lib/Box"
import { playerActions } from "src/state/redux/player.slice"
import { UpDownChevron } from "src/components/lib/UpDownChevron"
import { Text } from "src/components/lib/Text"
import { Grid } from "src/components/lib/Grid"
import { useClickOutside } from "src/hooks/useClickOutside"
import { PageSection } from "src/components/lib/PageSection"
import styled from "@emotion/styled"
import { getPlaylists } from "../../state/redux/manifest.selectors"
import { useAppDispatch, useAppSelector } from "../../state/redux/hooks"
import { SetPlaylistModal } from "../SetManualPlaylistModal"
import { PlaylistItem } from "./PlaylistItem"

const ScrollShadow = styled.div(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  pointerEvents: "none",
  boxShadow: `inset 0 0 1.5rem 1rem ${theme.colors.bg.secondary}`,
}))

export function PlaylistsDrawer() {
  const playlists = useAppSelector(getPlaylists)
  const [playlistIdToConfirm, setPlaylistIdToConfirm] = useState<
    string | undefined
  >()
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isOpen = useAppSelector((state) => state.player.isPlaylistSelectOpen)

  const rootRef = useClickOutside<HTMLDivElement>(() => {
    if (isOpen) {
      dispatch(playerActions.setIsPlaylistSelectOpen(false))
    }
  }, isOpen)
  const bgColor = theme.colors.bg.secondary

  return (
    <Box
      ref={rootRef}
      css={{
        position: "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        transition: "transform .3s cubic-bezier(0.6, 0.3, 0, 1)",
        transform: isOpen ? "translateY(0)" : "translateY(calc(100%))",
        backgroundColor: "white",
        boxShadow: theme.shadows.lg,
        zIndex: theme.zIndex.drawers,
      }}
    >
      <SetPlaylistModal
        isOpen={!!playlistIdToConfirm}
        onRequestClose={() => setPlaylistIdToConfirm(undefined)}
        playlistId={playlistIdToConfirm}
        title={t("Change Playlist")}
      />
      <Box
        css={{
          // On light theme, the shadow are enough to make the drawer visible. On dark theme, we need to add a border.
          borderBottom:
            theme.isDark && isOpen
              ? theme.border.subtle
              : "1px solid transparent",
          transition: "border-color .3s ease-in-out",
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          transform: "translateY(-100%)",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <button
          css={{
            borderTopRightRadius: theme.radius.md,
            borderTopLeftRadius: theme.radius.md,
            background: bgColor,
            padding: ".5rem 1rem",
            boxShadow: theme.shadows.lg,
            ...theme.text.sm,
            fontWeight: theme.font.weight.medium,
            display: "flex",
            gap: ".5rem",
            alignItems: "center",
            border: theme.border.subtle,
            borderBottom: "none",
            position: "relative",
            bottom: "-1px",
          }}
          onClick={() =>
            dispatch(playerActions.setIsPlaylistSelectOpen(!isOpen))
          }
        >
          <UpDownChevron up={isOpen} />
          <Text color="accent" weight="medium">
            {t("PLAYLISTS")}
          </Text>
        </button>
      </Box>
      <PageSection
        py="1.5rem"
        css={css({
          background: bgColor,
          // to cover the shadow from the handle button
          position: "relative",
          maxHeight: theme.font.weight.medium,
          overflow: "auto",
        })}
      >
        <Grid gap="1.5rem" columns="repeat(auto-fill, minmax(12rem, 1fr))">
          {playlists.map((playlist) => (
            <PlaylistItem
              key={playlist.id}
              playlist={playlist}
              onClick={() => setPlaylistIdToConfirm(playlist.id)}
            />
          ))}
        </Grid>
      </PageSection>
      <ScrollShadow />
    </Box>
  )
}
