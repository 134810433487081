import { useRef } from "react"
import { useEventListener } from "./useEventListener"

export function useClickOutside<E extends HTMLElement>(
  handler: (event: MouseEvent | TouchEvent) => void,
  isActive = true,
) {
  const ref = useRef<HTMLDivElement>(null)
  const listener = (event: MouseEvent | TouchEvent) => {
    const node = event.target instanceof Node ? event.target : null
    if (![ref].some(({ current }) => current && current.contains(node))) {
      handler(event)
    }
  }
  useEventListener("click", listener, isActive)
  useEventListener("touchstart", listener, isActive)
  return ref
}
