import io, { Socket } from "socket.io-client"

import * as constants from "@spectre-music/shared/constants.js"
import log from "./log"
import { envConfig } from "./envConfig"

type DeviceOptions = {
  logo: string // logo full url
  mustSync?: boolean
  autoUpdateTime?: string
  autoRebootTime?: string
  ttl: number
  timeZone: string
  blacklist: string[]
}

type DeviceInfo = {
  uuid?: string
  serial?: string
  model?: string
  deviceId?: string
  OS?: string
  OSVersion?: string
  appVersion?: string
  locale?: string
  country?: string
  timeZone?: string
  network?: {
    wifi?: {
      ip?: string
    }
  }
  uptime?: string
}

type ZonesData = { [id: string]: { id: string; volumes: { music: number } } }

type EventData = {
  event: number
  zoneId?: string
  message?: string
  playState?: number
  trackId?: string
  playlistId?: string
  manualPlaylist?: boolean
  programId?: string
  volumes?: {
    music: number
    gain?: number
    messages?: number
  }
  dlState?: number
  downloadingTrackNumber?: number
  totalDownloadingTracks?: number
  downloadingTrack?: string
  dlStart?: Date
  missingTracks?: string[]
  username?: string
  reason?: string
}

type SetupData = {
  deviceId: string
  uuid: string
  token: string
}

interface FirstLaunchOptions {
  onDisconnect: () => void
  onSetupError: (error: any) => void
  onUnauthorized: (error: any) => void
  onSetupSuccess: (msg: { token: string; pin: string }) => void
  onReceiveToken: (token: string) => void
  onTokenError: (error: string) => void
}

interface ConnectOptions {
  onDisconnect: () => void
  onReceivedCommand: (
    command: Commands.AllCommands,
    param: string,
    username: string,
  ) => void
  onReceivedPacket: (type: string) => void
  onReceivedManifest: (manifest: Spectre.Manifest, fileCheck?: boolean) => void
  onReceiveToken: (token: string) => void
  onUnauthorized: (error: any) => void
  onDeviceAccepted: (deviceOptions: DeviceOptions) => void
  onDeviceRejected: (error: any) => void
  onTokenError: (error: string) => void
  onReceiveZonesData: (zones: ZonesData) => void
  onOptions: (options: DeviceOptions) => void
}

const validateDeviceOptions = (deviceOptions: DeviceOptions) => {
  if (typeof deviceOptions.ttl !== "number") {
    throw new Error("Invalid TTL")
  }
  if (!Array.isArray(deviceOptions.blacklist)) {
    throw new Error("Invalid blacklist")
  }
  if (deviceOptions.logo && typeof deviceOptions.logo !== "string") {
    throw new Error("Invalid logo")
  }
  if (
    deviceOptions.autoUpdateTime &&
    typeof deviceOptions.autoUpdateTime !== "string"
  ) {
    throw new Error("Invalid autoUpdateTime")
  }
  if (
    deviceOptions.autoRebootTime &&
    typeof deviceOptions.autoRebootTime !== "string"
  ) {
    throw new Error("Invalid autoRebootTime")
  }
  if (deviceOptions.timeZone && typeof deviceOptions.timeZone !== "string") {
    throw new Error("Invalid timeZone")
  }
}

export class SocketManager {
  socket: Socket | null = null
  connected = false
  authenticated = false
  deferred: any[] = []
  listening = false
  uuid: string | undefined = undefined
  deviceInfo: DeviceInfo | undefined = undefined

  public setDeviceInfo(deviceInfo: DeviceInfo) {
    this.uuid = deviceInfo.uuid
    this.deviceInfo = deviceInfo
  }

  public firstLaunch(setupToken: string, options: FirstLaunchOptions) {
    if (this.socket) {
      this.socket.close()
    }
    this.createSocket(setupToken, options, true)
  }

  public connect(token: string, options: ConnectOptions) {
    if (this.socket) {
      this.socket.close()
    }
    this.createSocket(token, options)
  }

  private createSocket(
    token: string,
    options: Partial<FirstLaunchOptions & ConnectOptions>,
    isSetup = false,
  ) {
    // Connect socket
    this.socket = io(envConfig.VITE_VAUXHALL_URL, {
      randomizationFactor: 0.9,
      reconnectionDelay: 5000,
      reconnectionDelayMax: 30000,
    }) // Default timeout is 20 seconds

    // called each time a packet is received (see the doc https://socket.io/docs/v4/client-api/#socketio)
    this.socket.io.engine.on("packet", (packet) => {
      options.onReceivedPacket?.(packet.type)
    })

    this.socket
      ?.on("connect", () => {
        log.info(`[Socket][createSocket] connect`)
        this.connected = true
        this.socket?.emit("authenticate", {
          token,
          uuid: this.uuid,
          type: "largo",
        })
      })
      .on("error", (error) => {
        log.info(`[Socket][createSocket] error (${error.message})`)
      })
      .on("connect_error", (error) => {
        log.info(`[Socket][createSocket] connect_error (${error})`)
      })
      .on("connect_timeout", (timeout) => {
        log.info(`[Socket][createSocket] connect_timeout (${timeout})`)
      })
      .on("disconnect", (reason) => {
        log.info(`[Socket][createSocket] Disconnected (${reason})`)
        this.connected = false
        this.authenticated = false
        if (reason === "io server disconnect") {
          // the disconnection was initiated by the server, you need to reconnect manually
          this.socket?.connect()
        } else if (options?.onDisconnect) {
          options.onDisconnect()
        }
      })
      .on("reconnect", (attemptNumber) => {
        log.debug(`[Socket][createSocket] reconnect (attempt ${attemptNumber})`)
      })
      .on("reconnect_attempt", (attemptNumber) => {
        log.debug(
          `[Socket][createSocket] reconnect_attempt (attempt ${attemptNumber})`,
        )
      })
      .on("reconnecting", (attemptNumber) => {
        log.debug(
          `[Socket][createSocket] reconnecting (attempt ${attemptNumber})`,
        )
      })
      .on("reconnect_error", (error) => {
        log.debug(`[Socket][createSocket] reconnect_error (${error})`)
      })
      .on("reconnect_failed", () => {
        log.debug(`[Socket][createSocket] reconnect_failed`)
      })
      .on("authenticated", () => {
        log.info("[Socket][createSocket] Socket authenticated")

        if (!isSetup) {
          this.authenticated = true
        }

        this.socket?.emit(
          isSetup ? "setup" : "device",
          JSON.stringify({
            ...this.deviceInfo,
            lastConnection: Date.now(),
            type: "largo",
            date: new Date(),
          }),
        )
      })
      .on("unauthorized", (msg) => {
        log.warn(
          `[Socket][createSocket] Socket unauthorized: ${JSON.stringify(
            msg.data,
          )}`,
        )
        const code = msg.data.code
        if (code === "uuid_already_connected") {
          this.disconnect()
        }
        if (options?.onUnauthorized) {
          options.onUnauthorized(code)
        }
      })
      .on("setupSuccess", (msg: { token: string; pin: string }) => {
        if (msg.token) {
          log.info("[Socket][createSocket] Already setup")
        } else {
          log.info("[Socket][createSocket] Ready for setup")
        }
        if (options?.onSetupSuccess) {
          options.onSetupSuccess(msg)
        }
      })
      .on("setupError", (msg) => {
        if (options?.onSetupError) {
          options.onSetupError(new Error(msg.error))
        }
      })
      .on("token", async (msg) => {
        // Device was set up in dashboard
        log.info(`[Socket][createSocket] Got token ${msg}`)
        if (options?.onReceiveToken) {
          options.onReceiveToken(msg)
        }
      })
      .on("tokenError", (msg) => {
        log.info(`[Socket][createSocket] Token error: ${msg.error}`)
        if (options?.onTokenError) {
          options.onTokenError(msg.error)
        }
      })
      .on("options", (deviceOptions: DeviceOptions) => {
        log.info(`[Socket][createSocket] Got options`)
        if (options?.onOptions) {
          options.onOptions(deviceOptions)
        }
      })
      .on("deviceAccepted", async (deviceOptions: DeviceOptions) => {
        log.info(`[Socket][createSocket] Device accepted`)
        if (!this.listening) {
          this.listening = true
        }

        validateDeviceOptions(deviceOptions)

        if (deviceOptions.mustSync) {
          this.send("manifest")
        }

        this.sendDeferredEvents()

        if (options?.onDeviceAccepted) {
          options.onDeviceAccepted(deviceOptions)
        }
      })
      .on("deviceRejected", (msg) => {
        log.error(`[Socket][createSocket] Device rejected ${msg.error}`)
        if (options?.onDeviceRejected) {
          options.onDeviceRejected(new Error("DEVICE_REJECTED:" + msg.error))
        }
      })
      .on("unauthorized", (msg) => {
        log.warn(`[Socket][connect] Unauthorized: ${JSON.stringify(msg.data)}`)
      })
      .on("disconnect", (reason) => {
        log.info(`[Socket][createSocket] Disconnected (${reason})`)

        if (reason === "io server disconnect") {
          // the disconnection was initiated by the server, you need to reconnect manually
          this.socket?.connect()
        }
      })
      .on("zones", (zones: ZonesData) => {
        log.info(`[Socket][connect] Got zones`, zones)
        if (options?.onReceiveZonesData) {
          options.onReceiveZonesData(zones)
        }
      })
      .on(
        "command",
        async (msg: {
          command: Commands.AllCommands
          param: string
          username: string
        }) => {
          log.info(
            `[Socket][connect] Got command: ${msg.command} ${JSON.stringify(
              msg,
            )}`,
          )
          if (options?.onReceivedCommand) {
            await options.onReceivedCommand(
              msg.command,
              msg.param,
              msg.username,
            )
          }
        },
      )
      .on("manifest", async (msg) => {
        log.info(`[Socket][connect] Got command: manifest`)
        if (options?.onReceivedManifest) {
          await options.onReceivedManifest(msg)
        }
      })
      .on("manifestCheck", async (msg) => {
        log.info(`[Socket][connect] Got command: manifestCheck`)
        if (options?.onReceivedManifest) {
          await options.onReceivedManifest(msg, true)
        }
      })
      .on("ping", (ack: () => void) => {
        log.debug(
          "[Socket][connect] Received a ping message. Acknowledging in return.",
        )
        ack()
      })
  }

  public disconnect() {
    if (this.connected && this.socket) {
      this.socket.close()
      this.socket = null
      this.connected = false
      this.authenticated = false
      this.listening = false
    }
  }

  // Send an event to the server, deferring it if the device is not authenticated
  public send(event: string, data: any = {}, noDefer = false) {
    const enhancedData = {
      ...data,
      uuid: this.uuid,
      date: new Date(),
    }

    // If the device is authenticated
    if (this.connected && this.authenticated) {
      this.sendDeferredEvents()
      const json = JSON.stringify(enhancedData)
      log.info("[Socket][send] Sending " + event + ": " + json)
      this.socket?.emit(event, json)
    } else if (!noDefer) {
      if (this.deferred.length < 100) {
        log.info(
          `[Socket][send] Deferring ${event} ${enhancedData.event} because device is not authenticated`,
        )
        this.deferred.push({ event, enhancedData })
      } else {
        log.warn("[Socket][send] instance deferred queue full, skipping event")
      }
    }
  }

  public sendEvent(data: EventData) {
    this.send("event", data)
  }

  // Send the setup event to the server
  // the device does not need to be authenticated
  public sendSetup(data: SetupData) {
    if (this.socket && this.connected) {
      const event = "activate"
      const enhancedData = {
        ...data,
        date: new Date(),
      }
      const json = JSON.stringify(enhancedData)
      log.debug(`[Socket][sendSetup] Sending ${event}: ${json}`)
      this.socket?.emit(event, json)
    } else {
      log.warn(
        "[Socket][sendSetup] Socket is not connected, not sending setup event",
      )
    }
  }

  private sendDeferredEvents = () => {
    if (this.socket && this.authenticated) {
      if (this.deferred.length > 0) {
        try {
          // send deferred events
          log.debug(
            `[Socket][sendDeferredEvents] Sending ${this.deferred.length} deferred events`,
          )
          log.info(JSON.stringify(this.deferred))
          this.socket.emit(
            "deferred",
            JSON.stringify({ uuid: this.uuid, events: this.deferred }),
          )
          this.deferred = []
        } catch (e: any) {
          log.warn(
            `[Socket][sendDeferredEvents] Failed to send deferred events: ${e.message}`,
          )
          log.info(this.deferred.toString())
        }
      }
    }
  }

  public notifyRanOut(zoneId: string, programId: string) {
    this.sendEvent({
      event: constants.EVENT_RAN_OUT,
      playState: constants.PLAY_STATE_SILENCE,
      zoneId,
      programId,
      playlistId: "",
      trackId: "",
      manualPlaylist: false,
    })
  }

  public notifyPlayTrack(
    trackId: string,
    zoneId: string,
    isManualPlaylist: boolean,
    username: string,
    programPlaylistId?: string,
    programId?: string,
  ) {
    this.sendEvent({
      event: constants.EVENT_PLAY_TRACK,
      zoneId,
      playState: constants.PLAY_STATE_PLAYING,
      trackId,
      playlistId: programPlaylistId,
      manualPlaylist: isManualPlaylist,
      username,
      programId,
    })
  }
  public notifyPausePlayer(username: string, reason: string, zoneId?: string) {
    if (!zoneId) {
      log.error("[SpectreApi] notifyPlayTrack: zoneId is undefined")
      return
    }
    this.sendEvent({
      event: constants.EVENT_STOP,
      playState: constants.PLAY_STATE_STOPPED,
      username,
      reason,
      zoneId,
    })
  }
  public startSync(startDate: Date, numberOfTracks: number) {
    this.sendEvent({
      event: constants.EVENT_DOWNLOAD,
      dlState: constants.DL_STATE_DOWNLOADING,
      downloadingTrackNumber: 0,
      totalDownloadingTracks: numberOfTracks,
      dlStart: startDate,
    })
  }
  public syncProgress(
    startDate: Date,
    trackNumber: number,
    numberOfTracks: number,
    trackId?: string,
  ) {
    this.sendEvent({
      event: constants.EVENT_DOWNLOAD,
      downloadingTrack: trackId,
      totalDownloadingTracks: numberOfTracks,
      downloadingTrackNumber: trackNumber,
      dlState: constants.DL_STATE_DOWNLOADING,
      dlStart: startDate,
    })
  }
  public endSync(
    startDate: Date,
    missingTracks: string[],
    numberOfTracks: number,
  ) {
    this.sendEvent({
      event: constants.EVENT_STOP_DOWNLOAD,
      dlState: constants.DL_STATE_NOT_DOWNLOADING,
      dlStart: startDate,
      missingTracks,
      totalDownloadingTracks: numberOfTracks,
    })
  }

  public notifyBlacklistTrack = (trackId: string, username: string) => {
    this.sendEvent({
      event: constants.EVENT_BLACKLIST,
      trackId,
      username,
    })
  }

  public notifyVolumeChange = (
    volume: number,
    zoneId: string,
    username: string,
  ) => {
    if (volume < 0 || volume > 1) {
      throw new Error(
        "[Socket][notifyVolumeChange] volume is not in the range 0-1",
      )
    }
    this.sendEvent({
      event: constants.EVENT_VOLUME,
      zoneId,
      volumes: {
        music: volume + 1, // volume has to be between 1 and 2 in database
      },
      username,
    })
  }

  public notifyLogout = (reason: string) => {
    this.sendEvent({
      event: constants.EVENT_LOGOUT,
      message: reason,
    })
  }
}
