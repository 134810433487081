import { useRef, useEffect } from "react"

export const useAnimationFrames = (
  onFrame: (delta: number) => void,
  enabled = true,
) => {
  const requestRef = useRef<number>()
  const previousTimeRef = useRef<number>()
  const onFrameRef = useRef(onFrame)
  onFrameRef.current = onFrame

  useEffect(() => {
    if (!enabled) {
      return
    }
    const animate = (time: number) => {
      if (previousTimeRef.current !== undefined) {
        const deltaTime = time - previousTimeRef.current
        onFrameRef.current(deltaTime)
      }
      previousTimeRef.current = time
      requestRef.current = requestAnimationFrame(animate)
    }
    requestRef.current = requestAnimationFrame(animate)
    return () => cancelAnimationFrame(requestRef.current as number)
  }, [enabled])
}
