import clamp from "lodash/clamp"
import { pipe } from "src/utils/utils"
import { easeInOutCubic, easingBackOut, WaveformBars } from "./waveformUtils"
import { WaveformTheme, Fill, SectionColors } from "./WaveformTheme"

/** this is to scale each bar so it reveals the waveform with a left to right wave effect */
export const getBarAppearScaleFactor =
  (t: number, waveLength = 0.7) =>
  (progress: number) => {
    const travelDistance = 1 + waveLength
    const waveStart = travelDistance * t - waveLength
    return 1 - clamp((progress - waveStart) / waveLength, 0, 1)
  }

type DrawWaveformOptions = {
  canvas: HTMLCanvasElement
  bars?: WaveformBars
  playbackProgress?: number
  ghostProgress?: number
  theme: WaveformTheme
  /** progress of the appear animation (0..1) */
  t?: number
}

export const drawWaveform = ({
  canvas,
  bars,
  playbackProgress,
  ghostProgress,
  theme,
  t = 1,
}: DrawWaveformOptions) => {
  // set canvas height/width to keep consistent drawing scale
  canvas.width = canvas.offsetWidth
  canvas.height = canvas.offsetHeight

  const ctx = canvas.getContext("2d")!

  // scale theme px units to 0..1 values
  const progressBarWidth =
    theme.progressBar && theme.progressBar.width / canvas.width

  /** Convert a 0..1 X coordinate to a coordinate on the canvas */
  const cx = (x: number, snap = true) => {
    const value = x * canvas.width
    return snap ? Math.round(value) : value
  }
  /** Convert a 0..1 Y coordinate to a coordinate on the canvas */
  const cy = (y: number) => Math.round(y * canvas.height)

  const setFill = (fill: Fill, x0: number, x1: number) => {
    if (typeof fill === "string") {
      ctx.fillStyle = fill
    } else {
      const gradient = ctx.createLinearGradient(cx(x0), 0, cx(x1), 0)
      fill.forEach((color, i) => gradient.addColorStop(i, color))
      ctx.fillStyle = gradient
    }
  }

  // For eased appear animation with wave effect
  const getBarAppearScaleFactorEased = (n: number) =>
    pipe(n, getBarAppearScaleFactor(easeInOutCubic(t)), easingBackOut)

  const drawBars = (xStart: number, xEnd: number, fill: Fill | undefined) => {
    if (!fill || !bars || !theme.bars) {
      return
    }
    setFill(fill, xStart, xEnd)
    ctx.beginPath()
    bars.values.forEach((value, i) => {
      const x = i * (bars.width + bars.gap)
      if (x + bars.width >= xStart && x <= xEnd) {
        const scaleFactor = getBarAppearScaleFactorEased(i / bars.values.length)
        const pxHeight = Math.max(
          cy(value * theme.bars!.maxAmplitude * scaleFactor),
          theme.bars?.width ?? 0,
        )
        // if heights is too small we don't draw the bar
        ctx.roundRect(
          cx(x),
          (cy(1) - pxHeight) / 2,
          cx(bars.width),
          pxHeight,
          theme.bars?.radius ?? 0,
        )
      }
    })
    ctx.fill()
  }

  const drawSection = (
    xStart: number,
    xEnd: number,
    colors: SectionColors,
    opacity = 1,
  ) => {
    if (xStart > xEnd) {
      ;[xStart, xEnd] = [xEnd, xStart]
    }

    ctx.save()
    ctx.globalAlpha = opacity

    // clip so that drawings can't overstep section area
    ctx.beginPath()
    ctx.rect(cx(xStart, false), 0, cx(xEnd - xStart, false), cy(1))
    ctx.clip()

    // background
    if (colors.background) {
      setFill(colors.background, xStart, xEnd)
      ctx.fillRect(xStart, 0, xEnd - xStart, 1)
    }

    // bars
    if (bars && theme.bars) {
      drawBars(xStart, xEnd, colors.bars)
    }

    ctx.restore()
  }

  const drawProgressBar = (x: number | undefined, opacity = 1) => {
    if (theme.progressBar && progressBarWidth && x !== undefined) {
      ctx.globalAlpha = opacity
      ctx.fillStyle = theme.progressBar.color
      ctx.fillRect(cx(x), 0, cx(-progressBarWidth), cy(1))
      ctx.globalAlpha = 1
    }
  }

  // off section
  drawSection(playbackProgress || 0, 1, theme.offColors)

  // active section
  if (playbackProgress) {
    drawSection(0, playbackProgress, theme.activeColors)
  }

  // ghost section
  if (ghostProgress !== undefined) {
    drawSection(
      playbackProgress || 0,
      ghostProgress,
      ghostProgress > (playbackProgress || 0)
        ? theme.activeColors
        : theme.offColors,
      theme.ghostOpacity,
    )
    if (theme.progressBar) {
      drawProgressBar(ghostProgress, theme.progressBar.ghostOpacity)
    }
  }

  // draw progressbar on top of all sections
  drawProgressBar(playbackProgress)
}
