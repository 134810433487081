import styled from "@emotion/styled"
import { keyframes } from "@emotion/react"
import { Loader as LoaderIcon } from "react-feather"

const kf = keyframes({
  "0%": {
    transform: "rotate(0deg)",
  },
  "100%": {
    transform: "rotate(360deg)",
  },
})

export const Loader = styled(LoaderIcon)({
  animation: `${kf} 3s linear infinite`,
})
