import validateManifest from "@spectre-music/shared/validator/validateManifest.js"
import { createSlice } from "@reduxjs/toolkit"
import log from "../../log"
import { storage } from "../services/storage"
import { getPlaylists } from "./manifest.selectors"
import { AppThunk } from "./store"
import { tracksActions } from "./tracks.slice"
import { playerActions } from "./player.slice"

export interface ManifestState {
  data?: Spectre.Manifest
  temp?: Spectre.Manifest
}

const initialState: ManifestState = {
  data: storage.manifest.get(),
}

export const manifestSlice = createSlice({
  name: "manifest",
  initialState,
  reducers: {
    setManifest: (state, { payload }) => {
      state.data = payload
    },
    setTemporaryManifest: (state, { payload }) => {
      state.temp = payload
    },
    clearTemporaryManifest: (state) => {
      state.temp = undefined
    },
  },
})

const saveManifest =
  (manifestData: Spectre.Manifest, fileCheck: boolean): AppThunk =>
  async (dispatch) => {
    try {
      // Check the manifest schema at runtime
      validateManifest(manifestData)

      // Update the manifest state
      dispatch(manifestSlice.actions.setTemporaryManifest(manifestData))

      await dispatch(tracksActions.syncTracks(fileCheck))
      dispatch(manifestSlice.actions.setManifest(manifestData))
      // Save the manifest to the local storage
      storage.manifest.set(manifestData)
      dispatch(manifestSlice.actions.clearTemporaryManifest())
      dispatch(manifestActions.preloadPlaylistImages())
      dispatch(playerActions.playNextTrack("saveManifest"))
    } catch (e) {
      log.error("Error while saving manifest:", e)
      // Delete the temporary manifest
      dispatch(manifestSlice.actions.clearTemporaryManifest())
    }
  }

/**
 * Resume the sync if a sync have not finish
 * This is the case when the player page is refreshed during a sync
 */
const resumeSyncIfNeeded =
  (): AppThunk =>
  async (dispatch, getState, { socket }) => {
    // Check if a temporary manifest exists
    const temporaryManifest = getState().manifest.temp
    const isTemporaryManifestExists =
      temporaryManifest !== undefined && temporaryManifest !== null
    if (isTemporaryManifestExists) {
      // Resume the sync
      log.info("Resuming sync because a temporary manifest exists")
      // Ask the server to send the manifest to re-start a sync
      await socket.send("manifest")
    }
  }

export const manifestActions = {
  saveManifest,
  resumeSyncIfNeeded,
  ...manifestSlice.actions,
  preloadPlaylistImages: (): AppThunk => (dispatch, getState) => {
    const playlists = getPlaylists(getState())
    playlists?.forEach((playlist) => {
      if (playlist.image) {
        // service-worker will cache the loaded image
        new Image().src = playlist.image
      }
    })
  },
}
