import { useTheme } from "@emotion/react"
import { SVGProps } from "react"
import { TextColor } from "src/styles/theme"

export function SpectreLogo({
  color,
  ...props
}: { color?: TextColor } & SVGProps<SVGSVGElement>) {
  const theme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={125}
      height={19}
      fill="none"
      {...props}
    >
      <path
        fill={color ? theme.colors.text[color] : "currentColor"}
        d="M117.574 15.505V10.44h6.579v-2.42h-6.579V3.519h7.289V1.075h-10.014v16.872H125v-2.442h-7.426ZM7.808 8.338l-1.692-.294c-2.143-.328-2.772-.797-2.772-2.02v-.681c0-1.458.94-2.144 2.938-2.144 2.373 0 3.172.73 3.215 2.96H12.5C12.12 2.99 9.654.827 6.294.827 3.192.826.56 3.13.56 5.879c0 2.397 1.903 4.253 4.888 4.716l1.667.26c2.282.328 2.787.705 2.787 1.856v.774c0 1.576-1.071 2.305-3.361 2.305-2.63 0-3.501-.776-3.572-3.267H0c.283 3.336 2.937 5.64 6.51 5.64 3.431 0 6.218-2.373 6.218-5.312.009-2.491-1.612-3.992-4.926-4.524l.006.011Zm18.46-7.272h-5.71v16.872h2.724v-5.687h3.055c3.216 0 5.83-2.466 5.83-5.574 0-3.143-2.61-5.611-5.898-5.611Zm3.08 6.322c0 1.621-1.01 2.419-3.08 2.419h-2.986V3.51h2.94c2.09 0 3.123.8 3.123 2.42l.002 1.458Zm77.508-1.059c0-2.96-2.585-5.263-5.897-5.263H95.41v16.872h2.725v-6.343h2.311l3.973 6.343h3.241l-4.534-6.815c2.223-.798 3.728-2.607 3.728-4.794h.002Zm-8.719 2.869V3.486h2.914c1.929 0 2.916.753 2.916 2.28v1.15c0 1.501-.965 2.282-2.916 2.282h-2.914Zm-21.953-5.64h4.773v14.38h2.724V3.558h4.76V1.065H76.182l.002 2.491Zm-12.826-.26c2.512 0 3.946 1.363 4.04 4.348h3.056C70.024 3.814 66.929.83 63.379.83c-4.17 0-7.31 3.808-7.31 8.672 0 4.865 3.147 8.672 7.308 8.672 3.548 0 6.646-2.984 7.075-6.815h-3.064c-.07 2.96-1.529 4.348-4.041 4.348-2.916 0-4.324-1.553-4.324-4.96V8.28c0-3.43 1.408-4.982 4.334-4.982ZM42.052 10.44h6.581v-2.42h-6.581V3.519h7.289V1.075H39.316v16.872h10.151v-2.442h-7.426l.01-5.064Z"
      />
    </svg>
  )
}
