import styled from "@emotion/styled"
import {
  FontFamily,
  FontWeight,
  TextColor,
  ThemeFontSize,
} from "src/styles/theme"
import { Box } from "./Box"

export const Text = styled(Box.withComponent("span"))<{
  color?: TextColor
  size?: ThemeFontSize
  opacity?: number
  weight?: FontWeight
  font?: FontFamily
}>(({ color, size, theme, opacity, weight, font }) => ({
  color: color && theme.colors.text[color],
  ...(size && theme.text[size]),
  opacity: opacity,
  fontWeight: weight && theme.font.weight[weight],
  fontFamily: font && theme.font.family[font],
}))

export type TextProps = React.ComponentProps<typeof Text>
