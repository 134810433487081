import { useAppSelector } from "../state/redux/hooks"

// Display the pin code
export function FirstLoadPage() {
  const { pin } = useAppSelector((state) => state.auth)
  return (
    <div>
      firstLoadPage
      {pin && <p>Pin: {pin}</p>}
    </div>
  )
}
