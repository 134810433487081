import type { ComponentProps } from "react"
import { ChevronDown, ChevronUp } from "react-feather"

export function UpDownChevron({
  up,
  ...props
}: { up?: boolean } & ComponentProps<typeof ChevronDown>) {
  return (
    <ChevronUp
      style={{
        transition: "transform 300ms",
        transform: up ? "scaleY(-1)" : undefined,
      }}
      {...props}
    />
  )
}
