import { Global, useTheme } from "@emotion/react"

import KareliaWebBold from "./fonts/KareliaWeb-Bold.woff2"
import KareliaWebMedium from "./fonts/KareliaWeb-Medium.woff2"
import KareliaWebRegular from "./fonts/KareliaWeb-Regular.woff2"
import UntitledSansWebBold from "./fonts/untitled-sans-web-bold.woff2"
import UntitledSansWebMedium from "./fonts/untitled-sans-web-medium.woff2"
import UntitledSansWebRegular from "./fonts/untitled-sans-web-regular.woff2"

const fonts = [
  { weight: "700", family: "Karelia", file: KareliaWebBold },
  { weight: "500", family: "Karelia", file: KareliaWebMedium },
  { weight: "400", family: "Karelia", file: KareliaWebRegular },
  { weight: "700", family: "Untitled Sans", file: UntitledSansWebBold },
  { weight: "500", family: "Untitled Sans", file: UntitledSansWebMedium },
  { weight: "400", family: "Untitled Sans", file: UntitledSansWebRegular },
]

export function GlobalCss() {
  const theme = useTheme()
  return (
    <Global
      styles={[
        ...fonts.map(({ file, family, weight }) => ({
          "@font-face": {
            fontFamily: family,
            fontWeight: weight,
            src: `url(${file}) format("woff2")`,
            fontDisplay: "swap",
          },
        })),
        {
          "html, body": {
            height: "100%",
            color: theme.colors.text.primary,
            accentColor: theme.colors.accent,
            background: theme.colors.bg.primary,
            minWidth: 1024,
            fontFamily: "Karelia, sans-serif",
          },
          "*, *::before, *::after": {
            boxSizing: "border-box",
          },
          "*": {
            transition: "background-color 0.2s ease",
          },
          "input, button, textarea, select": {
            font: "inherit",
          },
          "button, select": {
            textTransform: "none",
          },
          img: {
            display: "block",
            maxWidth: "100%",
            height: "auto",
          },
          button: {
            background: "none",
            color: "inherit",
            border: "none",
            padding: 0,
            font: "inherit",
            cursor: "pointer",
            outline: "inherit",
            textAlign: "inherit",
          },
          body: {
            margin: 0,
            padding: 0,
            fontSize: 18,
            lineHeight: 1.2,
            fontFamily:
              "'Untitled Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
            WebkitFontSmoothing: "antialiased",
            "@media screen and (max-device-width: 500px)": {
              WebkitTextSizeAdjust: "100%",
            },
          },
          ".ReactModalPortal > *": {
            opacity: 0,
          },
          ".ReactModal__Overlay": {
            transition: "opacity .2s cubic-bezier(0.6, 0.3, 0, 1)",
            "&--after-open": {
              opacity: 1,
            },
            "&--before-close": {
              opacity: 0,
            },
          },
        },
      ]}
    />
  )
}
