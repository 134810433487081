import type { RootState } from "./store"

export const isTTLExpired = (state: RootState) => {
  const { ttl, lastTimeConnected } = state.killSwitch

  if (
    ttl > 0 &&
    typeof lastTimeConnected === "number" &&
    lastTimeConnected > 0
  ) {
    // If the kill switch is enabled, we check if the ttl is still valid
    const now = new Date()
    const offlineSinceInDays =
      (now.getTime() - lastTimeConnected) / 1000 / 60 / 60 / 24
    // If it returns true that means
    // that he app will be blocked because the device has been offline for too long
    return offlineSinceInDays > ttl
  } else {
    return false
  }
}
