import { RootState } from "./store"

export const getTracksStatus = (state: RootState) => {
  const { syncState, firstSync } = state.tracks
  if (state.tracks.loadedCount < 1) {
    return "noTracks"
  } else if (syncState == "syncing" && firstSync == true) {
    return "loading"
  } else if (state.tracks.syncState === "error" && state.tracks.firstSync) {
    // This is a very specific case, when the first sync fails
    // because we cannot load the player and need to show an error page
    return "firstSyncError"
  }
  return "loaded"
}

export const isSyncing = (state: RootState) => {
  return state.tracks.syncState === "syncing"
}
