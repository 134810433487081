export function KillSwitchPage() {
  return (
    <div>
      The player has been offline for too long and was automatically disabled.
      <br />
      <br />
      To re-enable your player, reconnect your computer to Internet.
      <br />
      If this does not work:
      <br />
      1. Verify with your IT administrator/partner that your network&lsquo;s
      security policies are not misconfigured.
      <br />
      2. Get in touch w/ Spectre Support (+33 1 43 87 12 34,
      support@spectre-music.com)
    </div>
  )
}
