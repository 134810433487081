import { useAppSelector } from "../state/redux/hooks"
import { getAllTrackIdsFromTemporaryManifest } from "../state/redux/manifest.selectors"

export function LoadingPage() {
  const { loadedCount } = useAppSelector((state) => state.tracks)
  const totalCount = useAppSelector(getAllTrackIdsFromTemporaryManifest).length
  return (
    <div>
      <p>
        Loading ({loadedCount}/{totalCount})...
      </p>
    </div>
  )
}
