import axios from "axios"
import { envConfig } from "src/envConfig"
import log from "../../log"

const getChecksumFromResponse = (response: Response) => {
  const etag = response.headers.get("etag")
  if (etag && etag.indexOf("-") === -1) {
    return etag.replace(/"/g, "")
  }
  return null
}

export const spectreApi = {
  async downloadTrack(url: string, attempt = 0): Promise<Blob> {
    log.info(
      `[SpectreApi][downloadTrack] Downloading ${url} (attempt ${attempt})`,
    )
    const controller = new AbortController()
    // Set initial timeout to 10 seconds
    let noDataReceivedTimeout = setTimeout(() => {
      log.warn(
        `[SpectreApi][downloadTrack] No data received for 10 seconds while starting to download ${url} (attempt ${attempt})`,
      )
      // cancel the request
      controller.abort()
    }, 10000)
    try {
      const response = await axios.get(url, {
        responseType: "blob",
        // Global timeout is set to 1 hour
        timeout: 3600000,
        signal: controller.signal,
        onDownloadProgress: () => {
          // Reset the timeout when new data is received
          if (noDataReceivedTimeout) {
            clearTimeout(noDataReceivedTimeout)
          }
          // Set a timeout to about the request if no data is received in the next 30 seconds
          noDataReceivedTimeout = setTimeout(() => {
            log.warn(
              `[SpectreApi][downloadTrack] No data received for 30 seconds while downloading ${url} (attempt ${attempt})`,
            )
            // cancel the request
            controller.abort()
          }, 30000)
        },
      })
      // Clear the timeout since the download is finished
      clearTimeout(noDataReceivedTimeout)

      const blob = response.data
      log.info(`[SpectreApi][downloadTrack] Downloaded ${url}`)
      return blob
    } catch (e) {
      clearTimeout(noDataReceivedTimeout)
      log.error(
        `[SpectreApi][downloadTrack] Error while downloading ${url} (attempt ${attempt}):`,
        e,
      )
      if (attempt < 5) {
        // If the download fails, retry up to 4 times
        return spectreApi.downloadTrack(url, attempt + 1)
      }
      // After the 4th attempt, throw the error
      throw e
    }
  },
  async getTrackChecksum(url: string): Promise<string> {
    const res = await fetch(url, {
      method: "HEAD",
    })
    if (!res.ok) {
      throw new Error(`HTTP ${res.status} ${url}`)
    }
    const checksum = getChecksumFromResponse(res)
    if (!checksum) {
      throw new Error(`No checksum found for ${url}`)
    }
    return checksum
  },
  async getSetupToken(uuid: string, apiKey: string): Promise<string> {
    const response = await fetch(`${envConfig.VITE_VAUXHALL_URL}/setup`, {
      method: "POST",
      body: JSON.stringify({
        uuid: uuid,
        api_key: apiKey,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
    if (response.ok) {
      const responseJson = await response.json()
      if (typeof responseJson.token !== "string") {
        throw new Error(
          `[Auth][connectWithSetupToken] Error calling /setup: no token returned`,
        )
      }
      return responseJson.token
    } else {
      throw new Error(
        `[Auth][connectWithSetupToken] Error calling /setup: ${response.status} ${response.statusText}`,
      )
    }
  },
}

export type SpectreApi = typeof spectreApi
