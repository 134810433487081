import { useTranslation } from "react-i18next"
import { useAppSelector } from "../state/redux/hooks"
import { Box } from "./lib/Box"
import { Text } from "./lib/Text"
import { Loader } from "./lib/Loader"

export function Footer() {
  const appVersion = useAppSelector((state) => state.device.appVersion)
  const { t } = useTranslation()
  const { syncState } = useAppSelector((state) => state.tracks)

  return (
    <Box
      id="appVersion"
      mt="auto"
      p="1rem 2rem"
      css={{ display: "flex", gap: "2rem" }}
    >
      <Text size="sm" color="ternary">
        {t("Version: {{appVersion}}", { appVersion })}
      </Text>
      {syncState === "syncing" && (
        <Text
          size="sm"
          color="ternary"
          ml="auto"
          css={{ display: "flex", gap: ".25rem", alignItems: "center" }}
        >
          <Loader size={16} />
          {t("Syncing…")}
        </Text>
      )}
    </Box>
  )
}
