import { openDB, DBSchema } from "idb"
import log from "../../log"

const DB_VERSION = 2

interface LocalDB extends DBSchema {
  tracks: {
    key: string
    value: Blob
  }
}

let _db: ReturnType<typeof _openDB>
const _openDB = () =>
  openDB<LocalDB>("spectre", DB_VERSION, {
    upgrade(db) {
      console.info("Upgrading db")
      db.createObjectStore("tracks")
    },
  })

const getDb = () => {
  if (!_db) {
    _db = _openDB()
  }
  return _db
}

export const tracksDB = {
  async add(trackUrl: string, blob: Blob) {
    const db = await getDb()
    await db.put("tracks", blob, trackUrl)
  },

  async remove(urls: string[]) {
    if (!urls.length) return
    try {
      const db = await getDb()
      const tx = db.transaction("tracks", "readwrite")
      await Promise.all(
        urls.map((url) => {
          log.info(`[TracksDB][remove] Removing track ${url}`)
          return tx.store.delete(url)
        }),
      )
    } catch (e) {
      log.error(`[TracksDB][remove] Error while removing tracks:`, e)
    }
  },

  async clear() {
    const db = await getDb()
    await db.clear("tracks")
  },

  async getAllUrls() {
    const db = await getDb()
    return db.getAllKeys("tracks")
  },

  async get(url: string) {
    const db = await getDb()
    const blob = await db.get("tracks", url)
    if (!blob) throw new Error(`No track found in local DB for key: ${url}`)
    return blob
  },
}

export type TracksDB = typeof tracksDB
