import { useTheme } from "@emotion/react"
import ReactModal from "react-modal"

export function Modal(props: ReactModal.Props) {
  const theme = useTheme()
  return (
    <ReactModal
      {...props}
      closeTimeoutMS={300} // Let time for close animation to finish
      style={{
        overlay: {
          display: "grid",
          alignContent: "center",
          justifyContent: "center",
          zIndex: theme.zIndex.modals,
          backgroundColor: theme.colors.bg.modal,
          backdropFilter: "blur(10px)",
          ...props.style?.overlay,
        },
        content: {
          position: "static",
          background: theme.colors.bg.primary,
          border: theme.border.subtle,
          boxShadow: theme.shadows.lg,
          borderRadius: theme.radius.lg,
          minWidth: "14rem",
          padding: 0,
          ...props.style?.content,
        },
      }}
    />
  )
}
